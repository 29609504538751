import React, { useEffect, useState, useReducer } from "react";
import Button from "../../components/Button";
// import ProductVariants from "../order_entry_components/ProductVariants"
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  handleCalculateItemTotal,
  getCustomers,
  createCustomerDocument,
} from "../../utils/globalFunctions";
import axios from "axios";
import { addressURL } from "../../utils/globalFunctions";
import ComboBox from "../../components/ComboBox";
import { createOrder } from "../../utils/globalFunctions";
import { Table } from "flowbite-react";
import { useSnackbar } from "react-simple-snackbar";
import Swal from "sweetalert2";
import OrderEntryItem from "../../components/OrderEntryItem";
import { useParams } from "react-router-dom";

import {
  getOrder,
  fetchStoreProducts,
  fetchStoreProductVariations,
  updateCreatedOrder,
  handleCalculateTotalOrder,
  createPersistentOrderEntry,
  getPersistentOrderEntry,
  getManufacturerById,
  deletePersistentOrderEntry,
  getStoreDiscounts,
} from "../../utils/globalFunctions";
import { Modal } from "flowbite-react";
import { toast } from "react-toastify";

import SelectVariantInput from "../../components/SelectVariantInput";
import { Switch } from "@headlessui/react";
import ShowSubItemTotal from "../../components/ShowSubItemTotal";
import { serverTimestamp } from "firebase/firestore";
import Skeleton from "react-loading-skeleton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const downloadOrder = (orderData, title) => {
  const pdf = new jsPDF();

  // Add the title to the PDF
  pdf.text(title, 10, 10); // (text, x, y)

  // Define the columns and data for the table
  const columns = [
    { header: "No", dataKey: "no" },
    { header: "Fabric", dataKey: "fabric" },
    { header: "Room Name", dataKey: "roomName" },
    { header: "Width", dataKey: "width" },
    { header: "Height", dataKey: "height" },
    { header: "Quantity", dataKey: "quantity" },
    { header: "Price", dataKey: "price" },
    { header: "Customizations", dataKey: "customizations" },
  ];

  // Map order data to table rows
  console.log(orderData);
  const data = orderData.map((d, index) => ({
    no: index + 1,
    fabric: d.selectedProduct?.fabric_name,
    roomName: d.user_inputs.room_name,
    width: `${d.user_inputs.width} ${
      d.user_inputs.measurement_type === "inch"
        ? d.user_inputs?.fractions?.width
        : ""
    } ${d.user_inputs.measurement_type}`,
    height: `${d.user_inputs.height} ${
      d.user_inputs.measurement_type === "inch"
        ? d.user_inputs?.fractions?.height
        : ""
    } ${d.user_inputs.measurement_type}`,
    quantity: d.quantity,
    price: d.itemTotal, // Replace this with your actual price calculation
    customizations: Object?.keys(d.variations)
      ?.map((variant) => d?.variants[variant].value)
      .join(", "),
  }));

  // Add the table to the PDF
  pdf.autoTable({
    startY: 20, // Starting y position for the table
    head: [columns.map((col) => col.header)],
    body: data.map((row) => columns.map((col) => row[col.dataKey])),
  });

  // Save the PDF
  pdf.save("table.pdf");
};

function deepClone(obj) {
  console.log(obj);

  if (obj === null || typeof obj !== "object") {
    // Return the value if obj is not an object
    return obj;
  }

  // Check if obj is a Firebase reference
  if (isFirebaseRef(obj)) {
    return obj;
  }

  let clonedObj;

  if (Array.isArray(obj)) {
    // Handle Array
    clonedObj = [];
    for (let i = 0; i < obj.length; i++) {
      clonedObj[i] = deepClone(obj[i]);
    }
  } else {
    // Handle Object
    clonedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(obj[key]);
      }
    }
  }

  return clonedObj;
}

function isFirebaseRef(obj) {
  // Check for common Firebase properties
  return (
    typeof obj === "object" &&
    (obj._delegate ||
      obj._document ||
      obj._document ||
      obj.firestore ||
      obj.auth ||
      obj.app)
  );
}
export default function OrderEntries({ user, order_id, setOrderID }) {
  const [orderData, setOrder] = useState([]);
  const [customerName, setCustomerName] = useState([]);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [itemEarning, setItemEarning] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState([]);
  const [addingCustomer, setAddingNewCustomer] = useState(false);
  const [indexVariant, setIndexVariant] = useState({});
  const [updateOrder, setUpdateOrder] = useState(false);
  const [fireStoreData, setFireStoreData] = useState({});
  const [variations, setVariations] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  console.log(products);
  const [openSnackbar, closeSnackbar] = useSnackbar({
    position: "top-center",
    style: {
      color: "white",
      backgroundColor: "#e53e3e",
      fontSize: "16px",
      textAlign: "center",

      borderRadius: "8px",
      opacity: "1",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",

      fontWeight: "500",
    },
  });
  const [openSnackbar2, closeSnackbar2] = useSnackbar({
    position: "top-center",
    style: {
      color: "white",
      backgroundColor: "#5fc71e",
      fontSize: "16px",
      textAlign: "center",

      borderRadius: "8px",
      opacity: "1",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",

      fontWeight: "500",
    },
  });
  const [userInputs, setUserInputs] = useState({});
  const [quantity, setQuantity] = useState("");
  const [itemTotal, setItemTotal] = useState(0);
  const [hide, setHide] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [persistentOrders, setPersistenOrders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [path, setPath] = useState("");
  const [saveOrderPopUp, setSaveOrderPopUp] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("percentage");
  const [tax, setTax] = useState(false);
  const [isDiscounted, setIsDiscounted] = useState(false);
  const [discounts, setDiscounts] = useState(null);
  const { customerId } = useParams();
  const [orderItem, setOrderItem] = useState({
    quantity: "",
    user_inputs: {
      width: "",
      height: "",
      room_name: "",
      measurement_type: "",
      fractions: {
        width: "",
        height: "",
      },
    },
    selectedProduct: {},
    variants: {},
    variations: {},
    notes: "",
  });
  console.log(orderItem);

  useEffect(() => {
    if (user.store_id) {
      fetchStoreProducts(user.store_id, discounts).then((res) => {
        setProducts(res);
        setLoadingProducts(false);
      });

      getManufacturerById(user.manufacturer).then((res) => {
        setManufacturer(res);
        setOrderItem({
          quantity: "",
          user_inputs: {
            width: "",
            height: "",
            room_name: "",
            measurement_type: res.metricSystem,
            fractions: {
              width: "",
              height: "",
            },
          },
          selectedProduct: {},
          variants: {},
          variations: {},
          notes: "",
        });
      });
    }
  }, [user.store_id, discounts]);

  useEffect(() => {
    setLoading(true);
    if (customerId) {
      setSelectedCustomer(customerId);
    }
    if (order_id) {
      setPath("update");
      setLoading(false);
      getOrder(order_id).then((data) => {
        if (data) {
          setOrder(data.orders);

          setUpdateOrder(true);
          console.log(data);
          setSelectedCustomer(data.customer_id);
          setFireStoreData(data);
          setIsDiscounted(data?.isDiscounted ? true : false);
          setDiscount(data.customerDiscount ? data.customerDiscount : 0);
          setDiscountType(
            data.customerDiscountType ? data.customerDiscountType : "percentage"
          );
          setTax(data.customerTax ? data.customerTax : false);
        } else {
          Swal.fire("Error!", "There is no order with this number", "error");
          // navigate("/new-orders");
        }
      });
    } else {
      setPath("entry");
      setLoading(false);
      // try to see if order has been saved to persistent order
      getPersistentOrderEntry(user.storeName).then((res) => {
        if (res) {
          setOpenModal(true);
          setPersistenOrders(res);
          // setOrder(res)
        }
      });

      // if(persistentOrder){
      //   setOrder(persistentOrder)
      // }
    }
  }, [order_id]);

  useEffect(() => {
    if (user.store_id) {
      setLoading2(true);
      getCustomers(user.store_id).then((res) => {
        setCustomers(res);
        setLoading2(false);
      });
      getStoreDiscounts(user.store_id).then((res) => {
        if (res) {
          setDiscounts(res);
          console.log(res);
        }
      });
    } else if (fireStoreData) {
      setLoading2(true);
      getCustomers(fireStoreData.store_id).then((res) => {
        setCustomers(res);
        setLoading2(false);
      });

      getStoreDiscounts(fireStoreData.store_id).then((res) => {
        if (res) {
          setDiscounts(res);
          console.log(res);
        }
      });
    }
  }, [fireStoreData]);

  const addNewCustomer = () => {
    let customer = document.getElementById("customerName").value;

    if (customer === "" || customer === undefined || customer === null) {
      openSnackbar("Please enter a customer name to add a new customer");
      return;
    }

    createCustomerDocument(customer, user)
      .then((res) => {
        if (res) {
          openSnackbar2("Customer added successfully");
          let cus_ = { id: res, customerName: customer };
          setCustomers((prevCustomers) => [...prevCustomers, cus_]);
          setSelectedCustomer(cus_.id);
          document.getElementById("customerName").value = "";
          setAddingNewCustomer(false);
        } else {
          openSnackbar(
            "There was an issue with adding new customer, please try again"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(
          "There was an issue with adding new customer, please try again"
        );
      });
  };

  useEffect(() => {
    if (fireStoreData && user.role === "admin" && discounts) {
      fetchStoreProducts(fireStoreData.store_id, discounts).then((res) => {
        setProducts(res);
        setLoadingProducts(false);
      });
    }
  }, [fireStoreData, user, discounts]);

  const handleAddWindowToOrder = async () => {
    if (checkItemCorrectness(orderItem, orderItem.variations)) {
      //first save it to the database persistent memory

      console.log(orderData);
      let arr = deepClone(orderData);
      console.log(orderItem);

      let item = deepClone(orderItem);

      arr.push(item);

      console.log(item);

      if (updateOrder) {
        // dont save into persistent order if its an update
        setOrder(arr);
        setOrderItem({
          quantity: "",
          user_inputs: {
            width: "",
            height: "",
            room_name: "",
            measurement_type: deepClone(item.user_inputs.measurement_type),
            fractions: {
              width: "1/16",
              height: "1/16",
            },
          },
          selectedProduct: deepClone(item.selectedProduct),
          variants: deepClone(item.variants),
          variations: deepClone(item.variations),
        });
        openSnackbar2("Order has been added succesfully");
        return;
      }

      if (user.role !== "admin") {
        await createPersistentOrderEntry(user.storeName, arr, discounts).then(
          (res) => {
            if (res) {
              setOrder(arr);
              let newItem = {
                quantity: "",
                user_inputs: {
                  width: "",
                  height: "",
                  room_name: "",
                  measurement_type: deepClone(
                    item.user_inputs.measurement_type
                  ),
                  fractions: {
                    width: "1/16",
                    height: "1/16",
                  },
                },
                selectedProduct: deepClone(item.selectedProduct),
                variants: deepClone(item.variants),
                variations: deepClone(item.variations),
              };
              setOrderItem(newItem);
              openSnackbar2("Order has been added succesfully");
            } else {
              openSnackbar("Failed to add item to your order.");
            }
          }
        );
      }
    }
  };

  function checkItemCorrectness(item, vars) {
    let correct = true;

    if (
      item.quantity === "" ||
      !item.selectedProduct?.id ||
      item.user_inputs.height === "" ||
      item.user_inputs.width === "" ||
      item.user_inputs.room_name === ""
    ) {
      correct = false;
      //check whats wrong
      let errMessage = !item.quantity
        ? "Please enter the quantity"
        : !item.selectedProduct?.id
        ? "Please select a product"
        : !item.user_inputs.height
        ? "Please enter the height"
        : !item.user_inputs.width
        ? "Please enter the width "
        : !item.user_inputs.room_name
        ? "Please enter the room name"
        : "";

      openSnackbar(errMessage);
    } else if (
      isNaN(item.user_inputs.height) ||
      isNaN(item.user_inputs.width) ||
      isNaN(item.quantity)
    ) {
      correct = false;
      openSnackbar(
        "Please enter a valid number for height,width and quantity "
      );
    } else if (Object.keys(vars).length !== Object.keys(item.variants).length) {
      //check whats wrong

      var missingVars = [];
      Object.keys(vars).forEach((variant) => {
        if (!Object.keys(item.variants).includes(variant)) {
          missingVars.push(variant);
        }
      });

      openSnackbar(
        `Please select a ${
          missingVars.length > 2
            ? missingVars.join(",").toLowerCase()
            : missingVars.length === 2
            ? missingVars.join(" and ").toLowerCase()
            : missingVars[0]
        } 
                    `
      );

      correct = false;
    }
    return correct;
  }

  function checkOrderCorrectness() {
    let correct = true;
    orderData?.forEach((item, index) => {
      // get different item.product_variants name

      if (
        item.quantity === "" ||
        !item.selectedProduct?.id ||
        item.user_inputs.height === "" ||
        item.user_inputs.width === "" ||
        item.user_inputs.room_name === ""
      ) {
        correct = false;
        //check whats wrong
        let errMessage = !item.quantity
          ? "Please enter the quantity for order item " + `${index + 1}`
          : !item.selectedProduct?.id
          ? "Please select a product for order item " + `${index + 1}`
          : !item.user_inputs.height
          ? "Please enter the height for  order item " + `${index + 1}`
          : !item.user_inputs.width
          ? "Please enter the width for order item " + `${index + 1}`
          : !item.user_inputs.room_name
          ? "Please enter the room name for order item " + `${index + 1}`
          : "";

        Swal.fire("Error!", errMessage, "error");
      } else if (
        isNaN(item.user_inputs.height) ||
        isNaN(item.user_inputs.width) ||
        isNaN(item.quantity)
      ) {
        correct = false;
        Swal.fire(
          "Error!",
          "Please enter a valid number for height,width and quantity ",
          "error"
        );
      } else if (
        Object.keys(item.variations).length !==
        Object.keys(item.variants).length
      ) {
        //check whats wrong
        var missingVars = [];
        Object.keys(item.variations).forEach((variant) => {
          if (!Object.keys(item.variants).includes(variant)) {
            missingVars.push(variant);
          }
        });

        Swal.fire({
          title: "Error!",
          text: `Please select a ${
            missingVars.length > 2
              ? missingVars.join(",").toLowerCase()
              : missingVars.length === 2
              ? missingVars.join(" and ").toLowerCase()
              : missingVars[0]
          } for ORDER ITEM ${index + 1}
                    `,
          icon: "error",
          confirmButtonText: "Ok",
        });

        correct = false;
      }
    });
    return correct;
  }

  function handleCreateOrder() {
    if (!selectedCustomer) {
      openSnackbar("Please select a customer to create the order");

      return;
    }
    if (orderData.length === 0) {
      Swal.fire("Error!", "Please add at least one window", "error");
      return;
    }
    // let validatedItems = orderData.filter(i=>validateItemVariantSelection(i)) NOT A BAD IDEA

    if (checkOrderCorrectness()) {
      let customer = customers.find(
        (customer) => customer.id === selectedCustomer
      );

      let manufacturers = new Set();
      orderData.forEach((item) => {
        manufacturers.add(item.selectedProduct.manufacturer_name);
        let varOrder = [];
        Object.keys(item.variations).forEach((variant) => {
          varOrder.push(variant);
        });
        item.variationOrder = varOrder;
      });

      let arr = JSON.parse(JSON.stringify(orderData));

      console.log(arr);

      let data = {
        order_id: "",
        order_category: "blinds",
        customer_id: customer.id,
        customer_name: customer.customerName,
        store_name: user.storeName,
        store_id: user.store_id,
        created_at: new Date(),
        status: "Created",
        is_deleted: false,
        orders: arr,
        customerTax: tax,
        customerDiscount: discount,
        total: total,
        // isDiscounted: user?.discount?.isActive ? true : false,
        appliedDiscounts: discounts === null ? [] : discounts,
        customerDiscountType: discountType,
        customerTotal: totalEarning,
        manufacturers: Array.from(manufacturers),
      };

      createOrder(data, user.store_id, discounts).then((val) => {
        if (val) {
          deletePersistentOrderEntry(user.storeName).then((res) => {
            if (res) {
              Swal.fire(
                "Success!",
                "Order created successfully",
                "success"
              ).then(() => {
                navigate("/orders");
              });
            } else {
              openSnackbar(
                "There was an error creating the order, please contact admin"
              );
            }
          });
        } else {
          Swal.fire("Error!", "There was an error creating the order", "error");
        }
      });
    }
  }

  const [loadingVariations, setLoadingVariations] = useState(false);

  useEffect(() => {
    let vars = {};
    console.log(selectedProduct);

    if (selectedProduct?.product_title) {
      setLoadingVariations(true);
      console.log(selectedProduct);

      fetchStoreProductVariations(selectedProduct, discounts).then((res) => {
        console.log(res);
        if (!res)
          return toast.error(
            "There was an error fetching product customizations"
          );

        res.forEach((variant) => {
          let variant_name = variant.variation_title;

          // Use map to transform variationItems into a new structure
          vars[variant_name] = variant.variationItems.map((item) => {
            let obj = {
              id: item.id,
              key: variant?.key ? variant.key : null,
              isRule: variant?.isRule ? variant.isRule : false,
              variant_value: item.variation_name,
              variant_name: variant_name,
              variant_price: item.variation_price,
              store_variant_price: item.store_variation_price,
              variant_details: [],
            };

            // Iterate over variation_details to populate variant_details in obj
            item.variation_details?.forEach((detail) => {
              detail.variation_detail_items.forEach((detailItem) => {
                obj.variant_details.push({
                  variant_detail_name: detail.variation_detail_title,
                  variant_detail_value: detailItem.variation_detail_name,
                  variant_detail_price: detailItem.variation_detail_price,
                  store_variant_detail_price:
                    detailItem.store_variation_detail_price,
                });
              });
            });

            return obj; // Return the transformed object for each item
          });
        });

        setOrderItem({
          ...orderItem,
          selectedProduct: {
            fabric_name: selectedProduct.product_title,
            id: selectedProduct.id,
            design_type: selectedProduct.product_category,
            price: selectedProduct.product_price,
            store_product_price: selectedProduct.store_product_price,
            manufacturer_name: selectedProduct?.creator,
            isPriceList: selectedProduct.isPriceList ? true : false,
            isMetric: selectedProduct.isMetric ? true : false,
            priceList: selectedProduct.priceList
              ? selectedProduct.priceList
              : [],
            storeProductPriceList: selectedProduct.storeProductPriceList
              ? selectedProduct.storeProductPriceList
              : [],
          },
          variants: {},
          variations: vars,
        });
        setLoadingVariations(false);
      });
    }
  }, [selectedProduct]);

  useEffect(() => {
    const calculate = async () => {
      let [mTotal, sTotal] = await handleCalculateTotalOrder(
        orderData,
        discounts
      );
      setTotal(mTotal);
      setTotalEarning(sTotal);
    };

    if (orderData.length === 0 && discounts) {
      setTotal(0);
      setTotalEarning(0);
    } else {
      calculate();
    }
  }, [orderData, discounts]);

  useEffect(() => {
    const calculate = async () => {
      let [mTotal, sTotal] = await handleCalculateItemTotal(
        orderItem,
        discounts
      );
      setItemTotal(mTotal);
      setItemEarning((sTotal - mTotal).toFixed(2));
    };
    calculate();
  }, [orderItem]);

  function handleUpdateOrder() {
    // check order id
    if (!order_id) {
      Swal.fire("Error!", "There was an error updating the order", "error");
      return;
    }

    if (orderData.length === 0) {
      Swal.fire("Error!", "Please add at least one window", "error");
      return;
    }

    if (checkOrderCorrectness()) {
      let customer = customers.find(
        (customer) => customer.id === selectedCustomer
      );

      // to preserver order variation

      let manufacturers = new Set();

      orderData.forEach((item, index) => {
        manufacturers.add(item.selectedProduct.manufacturer_name);
        let varOrder = [];
        Object.keys(item.variations).forEach((variant) => {
          // maybe remove it safely?
          varOrder.push(variant);
        });
        item.variationOrder = varOrder;
        item.orderIndex = index + 1;
      });

      // make manufacturers array
      manufacturers = Array.from(manufacturers);

      let arr = JSON.parse(JSON.stringify(orderData));

      let data = fireStoreData;
      console.log(data);
      data.orders = arr;
      data.total = user?.discount?.isActive
        ? total - (total * user.discount.value) / 100
        : total;
      data.customerTotal = totalEarning;
      data.updatedAt = serverTimestamp();
      data.manufacturers = manufacturers;
      data.customer_id = customer?.id ? customer.id : fireStoreData.customer_id;
      data.customer_name = customer?.customerName
        ? customer.customerName
        : fireStoreData.customer_name;
      data.customerTax = tax;
      data.customerDiscount = discount;
      data.appliedDiscounts = discounts === null ? [] : discounts;
      data.customerDiscountType = discountType;
      data.isDiscounted = user?.discount?.isActive ? true : false;
      data.discountPercentage = user?.discount?.value ? user.discount.value : 0;

      updateCreatedOrder(data, discounts).then((val) => {
        if (val) {
          Swal.fire("Success!", "Order updated successfully", "success");
        } else {
          Swal.fire("Error!", "There was an error updating the order", "error");
        }
      });
    }
  }

  function resetOrder() {
    setOrderItem({
      quantity: "",
      user_inputs: {
        width: "",
        height: "",
        room_name: "",
        measurement_type: "cm",
      },
      selectedProduct: {},
      variants: {},
      variations: {},
    });
    setOrder([]);
    deletePersistentOrderEntry(user.storeName).then((res) => {
      if (!res) {
        openSnackbar(
          "There was an error resetting the order, please contact admin"
        );
      }
    });
  }

  console.log(loadingVariations)

  return (
    <div>
      {user.status === "ONBOARDING" && (
        <>
          <div className="w-full h-full z-50 bg-black bg-opacity-50 absolute top-0 left-0 flex justify-center items-center">
            <div className="sm:w-1/2 sm:h-1/2 bg-white rounded p-4 flex flex-col justify-center items-center">
              <p className="text-center text-xl text-gray-600 font-bold">
                We are setting up your store, this may take few minutes.
              </p>
              <div className="sm:w-1/2 sm:h-1/2 bg-white rounded  flex flex-col justify-center items-center">
                <img
                  className="h-40"
                  src="https://i.pinimg.com/originals/48/c8/b4/48c8b4eaac2a55362b524278a635943f.gif"
                  alt="Your Icon"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {
        <div className="flex relative flex-col lg:flex-row w-full">
          {updateOrder && (
            <button
              onClick={() => {
                // navigate("/orders");
                setOrderID("");
              }}
              className="absolute top-16 right-2 bg-red-700 hover:bg-red-600 text-white rounded-md p-2 z-30"
            >
              Cancel
            </button>
          )}

          <div
            className={
              "flex z-10 md:relative flex-col lg:w-1/3 min-h-screen   items-center justify-center"
            }
          >
            {!loading && (
              <div className="md:absolute md:top-20  border-r">
                <div className="p-4 w-full ">
                  {path === "entry" && (
                    <>
                      <p className="font-black text-3xl sm:mt-0 mt-16">
                        Place an Order
                      </p>
                      <div className="h-2" />
                      <p className="text-xs">Select a customer</p>
                      <select
                        className="shadow-sm rounded-md appearance-none  border-gray-300 w-full py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                        id="customer"
                        name="customer"
                        value={selectedCustomer}
                        onChange={(e) => {
                          setSelectedCustomer(e.target.value);
                        }}
                      >
                        <option value={null}>Select a customer</option>
                        {customers?.map((customer, index) => {
                          return (
                            <option key={index} value={customer.id}>
                              {customer.customerName}
                            </option>
                          );
                        })}
                      </select>
                      <p
                        onClick={() => {
                          setAddingNewCustomer(true);
                        }}
                        className="text-xs underline cursor-pointer"
                      >
                        Create new customer
                      </p>

                      {addingCustomer && (
                        <div className="flex flex-col mt-2">
                          <input
                            type="text"
                            className="shadow-sm rounded-md appearance-none mb-1  border-gray-300 w-full py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                            id="customerName"
                            placeholder="Customer Name"
                          />
                          <Button
                            name="Add Customer"
                            onClick={addNewCustomer}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {path === "update" && (
                    <>
                      <p className="font-black text-4xl sm:mt-0 mt-12">
                        Update Order
                      </p>
                      <div className="h-2" />

                      <select
                        className="shadow-sm rounded-md appearance-none  border-gray-300 w-full py-2 px-3 text-gray-800 focus:outline-none focus:shadow-outline"
                        id="customer"
                        name="customer"
                        value={selectedCustomer}
                        onChange={(e) => {
                          setSelectedCustomer(e.target.value);
                        }}
                      >
                        <option value={null}>Select a customer</option>
                        {customers?.map((customer, index) => {
                          return (
                            <option key={index} value={customer.id}>
                              {customer.customerName}
                            </option>
                          );
                        })}
                      </select>

                      <div className="h-2" />
                    </>
                  )}

                  <div className="h-2" />
                </div>

                {!toggleView ? (
                  <Button
                    disabled={
                      fireStoreData.status !== "Created" &&
                      updateOrder &&
                      user.role === "store"
                    }
                    className={"hidden sm:block mx-auto"}
                    name="Add to Order"
                    onClick={handleAddWindowToOrder}
                  />
                ) : (
                  <Button
                    disabled={
                      fireStoreData.status !== "Created" &&
                      updateOrder &&
                      user.role === "store"
                    }
                    className={"hidden sm:block mx-auto"}
                    name="Done Editing"
                    onClick={() => {
                      if (
                        !checkItemCorrectness(orderItem, orderItem.variations)
                      ) {
                        return;
                      }

                      let updatedOrder = orderData.map((item, index) => {
                        if (index === indexVariant) return orderItem;
                        else return item;
                      });
                      //check if its correct

                      setOrder(updatedOrder);

                      setToggleView(false);
                      setOrderItem({
                        quantity: "",
                        user_inputs: {
                          width: "",
                          height: "",
                          room_name: "",
                          measurement_type: "cm",
                        },
                        selectedProduct: {},
                        variants: {},
                        variations: {},
                      });
                    }}
                  />
                )}

                <div className="pl-6 pr-6 sm:pb-4 mt-2 sm:w-[500px] w-[400px]">
                  {" "}
                  <div className="w-full  flex flex-col mb-2 bg-white border-solid border-2 border-gray-500 rounded-md hover:border-dotted p-4">
                    <div className="p-1 bg-red-100 rounded flex justify-between items-center">
                      <div className="flex flex-col">
                        <p className="font-bold text-gray-700 mb-1">
                          {" "}
                          Order Form
                        </p>
                        <p className="bg-white p-2 rounded w-21 ml-1 mb-1 ">
                          ${itemTotal}
                          {/* <a className="text-[11px] text-green-500">(Earn <a className="text-green-600 font-bold">${itemEarning}</a>)</a> */}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          //remove the editing item

                          let updatedOrder = orderData.filter((item, index) => {
                            return index !== indexVariant;
                          });

                          if (!updateOrder) {
                            if (updatedOrder.length === 0) {
                              deletePersistentOrderEntry(user.storeName).then(
                                (res) => {
                                  if (!res) {
                                    openSnackbar(
                                      "There was a resetting the order, please contact admin"
                                    );
                                  }
                                }
                              );
                            } else {
                              let arr = JSON.parse(
                                JSON.stringify(updatedOrder)
                              );

                              createPersistentOrderEntry(
                                user.storeName,
                                arr,
                                discounts
                              ).then((res) => {
                                if (!res) {
                                  openSnackbar(
                                    "There was an error updating the order, please contact admin"
                                  );
                                }
                              });
                            }
                          }

                          setOrder(updatedOrder);
                          setToggleView(false);
                          setIndexVariant("");
                          setOrderItem({
                            quantity: "",
                            user_inputs: {
                              width: "",
                              height: "",
                              room_name: "",
                              measurement_type: "cm",
                            },
                            selectedProduct: {},
                            variants: {},
                            variations: {},
                          });
                        }}
                        className="flex flex-col mr-4 hover:cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8 text-red-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className=" font-bold mt-1 text-gray-700 ">Room Name</p>
                    <div className="flex flex-row ">
                      <input
                        type="text"
                        onChange={(e) => {
                          setOrderItem({
                            ...orderItem,
                            user_inputs: {
                              ...orderItem.user_inputs,
                              room_name: e.target.value,
                            },
                          });
                        }}
                        value={orderItem.user_inputs.room_name}
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm mt-1"
                        placeholder="Enter Room Name"
                      />
                    </div>

                    <div className="flex flex-row mt-1">
                      <div>
                        <p className=" font-bold text-gray-700">
                          Measurement Type
                        </p>
                        <select
                          value={
                            orderItem.user_inputs.measurement_type ||
                            manufacturer?.metricSystem
                          }
                          onChange={(e) => {
                            if (e.target.value === "inch") {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  measurement_type: e.target.value,
                                  width: "",
                                  height: "",
                                  fractions: {
                                    width: "1/16",
                                    height: "1/16",
                                  },
                                },
                              });
                            } else {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  measurement_type: e.target.value,
                                },
                              });
                            }
                          }}
                          className="bg-white fixed-width  w-[100px] rounded  border border-gray-300 bg-white py-2 pl-3 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                        >
                          <option value="cm">cm</option>
                          <option value="inch">inch</option>
                        </select>
                      </div>
                    </div>

                    {orderItem.user_inputs.measurement_type === "cm" && (
                      <div className="flex flex-row mt-1">
                        <div>
                          <p className=" font-bold text-gray-700">Width</p>
                          <input
                            type="text"
                            onChange={(e) => {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  width: e.target.value,
                                },
                              });
                            }}
                            value={orderItem.user_inputs.width}
                            className="bg-white  w-full  rounded  border border-gray-300 bg-white py-2 pl-3 mr-1 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                            placeholder="Width"
                          />
                        </div>

                        <div>
                          <p className=" font-bold text-gray-700">Height</p>
                          <input
                            type="text"
                            onChange={(e) => {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  height: e.target.value,
                                },
                              });
                            }}
                            value={orderItem.user_inputs.height}
                            className="bg-white  w-full  rounded  border border-gray-300 bg-white py-2 pl-3 ml-1 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                            placeholder="Height"
                          />
                        </div>
                      </div>
                    )}

                    {orderItem.user_inputs.measurement_type === "inch" && (
                      <div className="flex flex-row mt-1">
                        <div>
                          <p className=" font-bold text-gray-700">Width</p>
                          <input
                            type="text"
                            onChange={(e) => {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  width: e.target.value,
                                },
                              });
                            }}
                            value={orderItem.user_inputs.width}
                            className="bg-white sm:w-24 w-16 rounded  border border-gray-300 bg-white py-2 pl-3 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                            placeholder="Width"
                          />
                        </div>

                        <div className="mt-7 ">
                          <select
                            className="border border-gray-300  border-l-0 w-18"
                            value={orderItem.user_inputs.fractions.width}
                            onChange={(e) => {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  fractions: {
                                    ...orderItem.user_inputs.fractions,
                                    width: e.target.value,
                                  },
                                },
                              });
                            }}
                          >
                            <option value="">0</option>
                            <option value="1/16">1/16</option>
                            <option value="1/8">1/8</option>
                            <option value="3/16">3/16</option>
                            <option value="1/4">1/4</option>
                            <option value="5/16">5/16</option>
                            <option value="3/8">3/8</option>
                            <option value="7/16">7/16</option>
                            <option value="1/2">1/2</option>
                            <option value="9/16">9/16</option>
                            <option value="5/8">5/8</option>
                            <option value="11/16">11/16</option>
                            <option value="3/4">3/4</option>
                            <option value="13/16">13/16</option>
                            <option value="7/8">7/8</option>
                            <option value="15/16">15/16</option>
                          </select>
                        </div>

                        <div>
                          <p className=" font-bold text-gray-700">Height</p>
                          <input
                            type="text"
                            onChange={(e) => {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  height: e.target.value,
                                },
                              });
                            }}
                            value={orderItem.user_inputs.height}
                            className="bg-white  sm:w-24 w-16 rounded  border border-gray-300 bg-white py-2 pl-3 ml-1 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                            placeholder="Height"
                          />
                        </div>

                        <div className="mt-7 ">
                          <select
                            className="border border-gray-300  border-l-0 w-18"
                            value={orderItem.user_inputs.fractions.height}
                            onChange={(e) => {
                              setOrderItem({
                                ...orderItem,
                                user_inputs: {
                                  ...orderItem.user_inputs,
                                  fractions: {
                                    ...orderItem.user_inputs.fractions,
                                    height: e.target.value,
                                  },
                                },
                              });
                            }}
                          >
                            <option value="">0</option>
                            <option value="1/16">1/16</option>
                            <option value="1/8">1/8</option>
                            <option value="3/16">3/16</option>
                            <option value="1/4">1/4</option>
                            <option value="5/16">5/16</option>
                            <option value="3/8">3/8</option>
                            <option value="7/16">7/16</option>
                            <option value="1/2">1/2</option>
                            <option value="9/16">9/16</option>
                            <option value="5/8">5/8</option>
                            <option value="11/16">11/16</option>
                            <option value="3/4">3/4</option>
                            <option value="13/16">13/16</option>
                            <option value="7/8">7/8</option>
                            <option value="15/16">15/16</option>
                          </select>
                        </div>
                      </div>
                    )}

                    <p className=" font-bold mt-1 text-gray-700">Quantity</p>
                    <div className="flex flex-row ">
                      <input
                        type="text"
                        onChange={(e) => {
                          setOrderItem({
                            ...orderItem,
                            quantity: e.target.value,
                          });
                        }}
                        value={orderItem.quantity}
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm mt-1"
                        placeholder="Enter Quantity"
                      />
                    </div>
                    <p className=" font-bold mt-1 text-gray-700">
                      Product{" "}
                      <a>
                        <a
                          onClick={() => {
                            setHide(!hide);
                          }}
                          className="underline cursor-pointer text-[10px]"
                        >
                          {hide ? "Show" : "Hide"}
                        </a>
                      </a>
                    </p>

                    {hide && (
                      <p>
                        {orderItem.selectedProduct?.fabric_name
                          ? orderItem.selectedProduct?.fabric_name
                          : "No Product Selected"}
                      </p>
                    )}
                    {/* <ProductSearch setOrder={setOrder} order={orderData} index={index} setRefresh={setRefresh} refresh={refresh}  /> */}

                    {!hide && (
                      <>
                        {loadingProducts ? (
                          <div className=" w-full justify-center items-center">
                            <Skeleton count={1} height={40} />
                          </div>
                        ) : (
                          <>
                            <ComboBox
                              products={products}
                              selectedProduct={orderItem.selectedProduct}
                              setSelectedProduct={setSelectedProduct}
                            />

                            {
                              loadingVariations ?
                              <div className=" w-full justify-center items-center">
                                <div class="flex items-center justify-center h-32">

                                <div class="w-12 h-12 border-t-4 border-gray-600 border-solid rounded-full animate-spin"></div>
                              </div>
                                </div>
                              :
                              <ProductVariants
                              item={orderItem}
                              index={indexVariant}
                              toggleView={toggleView}
                              setIndexVariant={setIndexVariant}
                              setOrder={setOrder}
                              orderData={orderData}
                              onSelectVariant={(variant) => {
                                setOrderItem({
                                  ...orderItem,
                                  variants: {
                                    ...orderItem.variants,
                                    [Object.keys(variant)[0]]:
                                      Object.values(variant)[0],
                                  },
                                });
                              }}
                              selectedProduct={selectedProduct}
                              refresh={indexVariant}
                              refreshId={indexVariant}
                            />

                            }
                          
                       
                          </>
                        )}
                      </>
                    )}

                    <p className=" font-bold mt-1 text-gray-700">Note</p>
                    <div className="flex flex-row ">
                      <input
                        type="text"
                        value={orderItem.notes ? orderItem.notes : ""}
                        onChange={(e) => {
                          setOrderItem({
                            ...orderItem,
                            notes: e.target.value,
                          });
                        }}
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm mt-1"
                        placeholder="Enter Note"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full">
                  {!toggleView ? (
                    <Button
                      disabled={
                        fireStoreData.status !== "Created" &&
                        updateOrder &&
                        user.role === "store"
                      }
                      className={"sm:hidden mx-auto"}
                      name="Add to Order"
                      onClick={handleAddWindowToOrder}
                    />
                  ) : (
                    <Button
                      disabled={
                        fireStoreData.status !== "Created" &&
                        updateOrder &&
                        user.role === "store"
                      }
                      className={"sm:hidden mx-auto"}
                      name="Done Editing"
                      onClick={() => {
                        // change the order with the updated one
                        let updatedOrder = orderData.map((item, index) => {
                          if (index === indexVariant) return orderItem;
                          else return item;
                        });
                        setOrder(updatedOrder);

                        setToggleView(false);
                        setOrderItem({
                          quantity: "",
                          user_inputs: {
                            width: "",
                            height: "",
                            room_name: "",
                            measurement_type: "cm",
                          },
                          selectedProduct: {},
                          variants: {},
                          variations: {},
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className={"flex flex-col lg:w-2/3 sm:pl-4 sm:pr-4 pb-16 w-full"}
          >
            <div
              className="
                      flex flex-row 
                      justify-center
                      w-full
                      "
            >
              <div
                className="flex  flex-col    sm:w-1/2 m-2  rounded-md hover:border-dotted pt-16         
                      bg-white"
              >
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <p className="text-sm font-bold">Summary</p>
                    <div>
                      {
                        <p className="text-xs text-green-800">
                          Customer total{" "}
                          {discountType === "percentage"
                            ? (
                                totalEarning -
                                parseFloat(totalEarning) *
                                  (parseFloat(discount) / 100) +
                                (parseFloat(totalEarning) -
                                  parseFloat(totalEarning) *
                                    (parseFloat(discount) / 100)) *
                                  (tax ? 0.13 : 0)
                              ).toFixed(2)
                            : (
                                parseFloat(totalEarning) -
                                parseFloat(discount) +
                                (parseFloat(totalEarning) -
                                  parseFloat(discount)) *
                                  (tax ? 0.13 : 0)
                              ).toFixed(2)}
                          CAD{" "}
                        </p>
                      }

                      {discounts && discounts.code ? (
                        <>
                          <p className="text-xs ">
                            You will pay <strong>{total}CAD</strong> to the
                            manufacturer.
                          </p>
                          <p className="text-xs">
                            Discount code <strong>{discounts.code}</strong> has
                            applied by manufacturer
                          </p>
                        </>
                      ) : (
                        <p className="text-xs">
                          You will pay {total}CAD to the manufacturer.
                        </p>
                      )}
                    </div>
                    <div
                      className="
                                  h-1
                              "
                    />
                    {path === "entry" && (
                      <Button
                        name={"Save Order"}
                        onClick={() => {
                          handleCreateOrder();
                        }}
                      />
                    )}
                    {path === "update" && (
                      <>
                        <Button
                          disabled={
                            fireStoreData.status !== "Created" &&
                            updateOrder &&
                            user.role === "store"
                          }
                          name={"Update Order"}
                          onClick={handleUpdateOrder}
                        />
                      </>
                    )}
                  </div>

                  <div className="flex flex-col mt-2">
                    <p className="text-sm font-bold">Order Discount</p>
                    <div className="flex flex-row">
                      <input
                        type="text"
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setDiscount(0);
                          } else {
                            setDiscount(parseFloat(e.target.value));
                          }
                        }}
                        value={discount === 0 ? "" : discount}
                        className="w-24 text-xs  rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm mt-1"
                        placeholder="Discount"
                      />
                      <select
                        value={discountType}
                        onChange={(e) => {
                          setDiscountType(e.target.value);
                        }}
                        className="bg-white fixed-width text-xs  w-24 rounded  border border-gray-300 bg-white py-2 pl-3 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                      >
                        <option value="percentage">%</option>
                        <option value="amount">Amount</option>
                      </select>
                    </div>
                    <p className="text-sm font-bold mt-2">Tax</p>
                    <div className="flex flex-row mt-1 ml-1">
                      <input
                        type="checkbox"
                        checked={tax}
                        onChange={(e) => {
                          setTax(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex flex-wrap gap-2 justify-center w-full mt-2">
              {/* tables here */}

              <div className="w-full overflow-x-auto">
                <div className="table-container">
                  <div className="w-full mx-auto" id="table-to-pdf">
                    <Table className="mb-4 border" hoverable>
                      <Table.Head>
                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">No</a>
                        </Table.HeadCell>

                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Fabric</a>
                        </Table.HeadCell>

                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Room Name</a>
                        </Table.HeadCell>

                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Width</a>
                        </Table.HeadCell>

                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Height</a>
                        </Table.HeadCell>
                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Quantity</a>
                        </Table.HeadCell>
                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Price</a>
                        </Table.HeadCell>
                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width">
                          <a className="flex text-xs">Customizations</a>
                        </Table.HeadCell>
                        <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width"></Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y ">
                        <>
                          {orderData.map((d, index) => (
                            <Table.Row className={"bg-white"}>
                              <Table.Cell className="p-6">
                                {index + 1}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.selectedProduct?.fabric_name}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.user_inputs.room_name}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                <p className="min-w-[80px]">
                                  {d.user_inputs.width}{" "}
                                  {d.user_inputs.measurement_type === "inch" &&
                                    d.user_inputs?.fractions?.width}{" "}
                                  {d.user_inputs.measurement_type}
                                </p>
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                <p className="min-w-[80px]">
                                  {d.user_inputs.height}{" "}
                                  {d.user_inputs.measurement_type === "inch" &&
                                    d.user_inputs?.fractions?.height}{" "}
                                  {d.user_inputs.measurement_type}
                                </p>
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                {d.quantity}
                              </Table.Cell>
                              <Table.Cell className="p-6">
                                <ShowSubItemTotal
                                  orderItem={d}
                                  discounts={discounts}
                                />
                              </Table.Cell>

                              <Table.Cell className="p-2 w-full">
                                {/* <div className="flex flex-col">
                                  {
                                    d.variants.map((variant)=>{
                                      return (
                                        <p className="text-xs">
                                          {Object.values(variant)[0].variant_name} 
                                        </p>
                                      )
                                    })
                          
                                  }
                                </div> */}

                                {Object?.keys(d.variations)?.map(
                                  (variant, index) => {
                                    return (
                                      <p key={index} className="text-xs">
                                        {d?.variants[variant]?.value}
                                        {d?.variants[variant]?.details &&
                                          Object.keys(
                                            d?.variants[variant]?.details
                                          ).length > 0 && (
                                            <>
                                              (
                                              {Object?.keys(
                                                d?.variants[variant]?.details
                                              ).map((detail, index) => {
                                                return (
                                                  <>
                                                    {
                                                      d.variants[variant]
                                                        .details[detail].value
                                                    }
                                                    {index !==
                                                      Object.keys(
                                                        d.variants[variant]
                                                          .details
                                                      ).length -
                                                        1 && (
                                                      <span className="text-xs">
                                                        ,
                                                      </span>
                                                    )}
                                                  </>
                                                );
                                              })}
                                              )
                                            </>
                                          )}
                                      </p>
                                    );
                                  }
                                )}
                              </Table.Cell>

                              <Table.Cell className="p-6">
                                <Button
                                  name={"Edit"}
                                  size={"xs"}
                                  onClick={() => {
                                    console.log(d);
                                    setOrderItem(d);
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                    setIndexVariant(index);
                                    setToggleView(true);
                                  }}
                                  className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                                />
                              </Table.Cell>
                              {/* <Table.Cell className="p-6">
                          <div className="flex justify-center">
                      <Button
                              name={"Delete"}
                          size={"xs"}
                          onClick={() => {
                              let newOrder = orderData.filter(
                              (item, i) => i !== index
                              );
                              setOrder(newOrder);
                          }}
                          className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded "
                          />
                      
              
                          </div>
                          </Table.Cell> */}
                            </Table.Row>
                          ))}
                        </>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Body>
          <div className="flex flex-col">
            <p className="text-sm">
              You have an unsaved order from your last session. Do you want to
              continue with this order or start a new one?
            </p>
            <div className="flex flex-row mt-4">
              <Button
                name="Continue"
                onClick={() => {
                  setOrder(persistentOrders.orderItems);
                  setOpenModal(false);
                }}
              />
              <Button
                name="Start New Order"
                className={"ml-2"}
                onClick={() => {
                  setOpenModal(false);
                  resetOrder();
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={saveOrderPopUp} onClose={() => setSaveOrderPopUp(false)}>
        <Modal.Body>
          <div className="flex flex-col">
            <p>Would you like to add discount and tax rate to the order?</p>
            <div>
              <div>
                <div>
                  <p className="text-sm">Customer Summary</p>
                  <p className="text-xs">Subtotal: {totalEarning} CAD</p>
                  <p className="text-xs">
                    Discount: {discount ? discount : "0"}{" "}
                    {discountType === "percentage" ? "%" : " CAD"}
                  </p>
                  <p className="text-xs">Tax: {tax ? "13" : "0"} %</p>
                  <p className="text-xs">
                    Total:{" "}
                    {discountType === "percentage"
                      ? (
                          totalEarning -
                          parseFloat(totalEarning) *
                            (parseFloat(discount) / 100) +
                          (parseFloat(totalEarning) -
                            parseFloat(totalEarning) *
                              (parseFloat(discount) / 100)) *
                            (tax ? 0.13 : 0)
                        ).toFixed(2)
                      : (
                          parseFloat(totalEarning) -
                          parseFloat(discount) +
                          (parseFloat(totalEarning) - parseFloat(discount)) *
                            (tax ? 0.13 : 0)
                        ).toFixed(2)}{" "}
                    CAD
                  </p>
                </div>
                <p className="text-sm font-bold text-gray-600 mt-4">Discount</p>

                <div className="flex">
                  <input
                    type="text"
                    onChange={(e) => {
                      if (e.target.value === "") setDiscount(0);
                      else setDiscount(parseFloat(e.target.value));
                    }}
                    value={discount}
                    className="rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm mt-1"
                    placeholder="Enter Discount"
                  />
                  <select
                    value={discountType}
                    onChange={(e) => {
                      setDiscountType(e.target.value);
                    }}
                    className="bg-white fixed-width  rounded  border border-gray-300 bg-white py-2 pl-3 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1"
                  >
                    <option value="percentage">Percentage</option>
                    <option value="amount">Amount</option>
                  </select>
                </div>

                <p className="text-sm font-bold text-gray-600 mt-2">Add Tax</p>
                <div className="flex">
                  <input
                    type="checkbox"
                    checked={tax}
                    onChange={(e) => {
                      setTax(!tax);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row mt-4">
                <Button
                  name="Save Order"
                  onClick={() => {
                    handleCreateOrder();
                  }}
                />
                <Button
                  name="Cancel"
                  className={"ml-2"}
                  onClick={() => {
                    setSaveOrderPopUp(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function ProductVariants({
  item,
  onSelectVariant,
  selectedProduct,
  refresh,
  refreshId,
  setOrder,
  orderData,
  index,
  toggleView,
}) {
  const [variants, setVariants] = useState({});

  useEffect(() => {
    if (item?.variations) {
      setVariants(item.variations);
    }
  }, [item]);

  const handleSelectVariant = (variant) => {
    onSelectVariant(variant); // Trigger onSelectVariant function passed as a prop
  };

  return (
    <div>
      {variants && (
        <div className="flex flex-col">
          <div className="flex flex-col">
            {Object.keys(variants).map((variant_name, i) => {
              return (
                <div key={i} className="flex flex-row mt-1">
                  <div className="flex flex-col w-full">
                    <p className=" font-bold text-gray-700 mb-2">
                      {variant_name}
                    </p>
                    <div className="flex flex-row w-full">
                      <SelectVariantInput
                        toggleView={toggleView}
                        refresh={refresh}
                        variantName={variant_name}
                        refreshId={refreshId}
                        variants={variants[variant_name]}
                        handleSelectVariant={handleSelectVariant}
                        item={item}
                        setOrder={setOrder}
                        orderData={orderData}
                        index={index}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
