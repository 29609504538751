import React, { useState, useEffect } from "react";
import {
  fetchStoreProducts,
  globalAlert,
  removeProductFromInventory,
  getStoreDiscounts
} from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import { Button, Checkbox, Table } from "flowbite-react";
import { updateStoreProduct } from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import { updateStoreProductPrices } from "../../utils/firebase";
import { HashtagIcon } from "@heroicons/react/20/solid";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { FingerPrintIcon } from "@heroicons/react/20/solid";
import { BellIcon } from "@heroicons/react/20/solid";
import {
  CubeIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
} from "@heroicons/react/20/solid";
import StoreDashboardTemplate from "../../components/StoreDashboardTemplate";
import ExcelEditor from "../../components/ExcelEditor";
import {getManufacturerPriceListByProductID} from '../../utils/globalFunctions'
import { toast } from "react-toastify";


const ExcelView = ({ product_id }) => {
  const [priceList, setPriceList] = useState(null);

  useEffect(() => {
    console.log(product_id);
    getManufacturerPriceListByProductID(product_id).then((res) => {
      console.log(res);
      console.log(res);
      setPriceList(res)
    });
  }, []);


  return (
    
      priceList !== null ?
      <div className="overflow-x-scroll">
        <p className="p-2">This is manufacturer's prices without discounts applied. Your manufacturer discounts only applied at your Order Entry form</p>
      <ExcelEditor data={priceList} setPriceList={setPriceList} increase={0} />
    </div>
    :
    <div>Loading...</div>
    
 
  );

   




}

export default function Products({ user }) {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [doItLater, setDoItLater] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [storeName, setStoreName] = useState("");
  const [oldStoreName, setOldStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [profitPercentage, setProfitPercentage] = useState(80);
  const [editPrice, setEditPrice] = useState("");
  const [fetchProducts, setFetchProducts] = useState(true);
  const [stores, setStores] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [openExcelLoader, setOpenExcelLoader] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#ffffff");
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchStoreProducts(user.store_id,null).then((data) => {
      setProducts(data);
      setIsLoading(false);
    });

  }, [fetchProducts]);

  const filterProducts = (products, query) => {
    return products?.filter((product) =>
      product.product_title.toLowerCase().includes(query.toLowerCase())
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        // HeaderIcon: <HashtagIcon width={10} />,
      },
      {
        Header: "Title",
      },

      {
        Header: "Price",
      },

      {
        Header: "Store Price",
        labelLeft: "$",
      },

      {
        Header: "Availability",
      },
      {
        Header: "Category",
      },
      {
        Header: "Manufacturer",
      },
    ],
    []
  );

  const secondaryNavigation = [
    {
      name: "Store Products",
      href: "#",
      icon: CubeIcon,
      current: true,
      onClick: () => {
        nav("/store-products");
      },
    },
    {
      name: "Global Customizations",
      href: "#",
      icon: CubeTransparentIcon,
      current: false,
      onClick: () => {
        nav("/store-product-customizations");
      },
    },
    {
      name: "Discover Products",
      href: "#",
      icon: GlobeAltIcon,
      current: false,
      onClick: () => {
        nav("/discover-new-products");
      },
    },
    // { name: 'Plan', href: '#', icon: CubeIcon, current: false },
    // { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
    // { name: 'Team members', href: '#', icon: UsersIcon, current: false },
  ];

  const handleUpdateProductPrice = (product) => {
    //check if the price is a number
    if (isNaN(product.store_product_price)) {
      alert("Store price must be a number");
      return;
    }

    // make sure its properly saved

    product.store_product_price = parseFloat(
      product.store_product_price
    ).toFixed(2);

    updateStoreProduct(user.role, user.store_id, product).then((res) => {
      console.log(res);
      setEditPrice("");
      if (!res) {
        alert(
          "Something went wrong while updating the price, please report this to admin"
        );
      }
    });
  };

  if(openExcelLoader){
    return (
    <>
    <div className="w-full h-full flex flex-col">


      <div className="sm:mt-4  mt-24 overflow-hidden sm:p-16">
        <button
          className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2 mr-2"
          onClick={() => {
            setOpenExcelLoader(false);
          }}
        >
          Close
        </button>
      <ExcelView product_id={openExcelLoader}  />
      </div>



   
    </div>
 
    </>
    )
  }

  return (
    <>
      <StoreDashboardTemplate secondaryNavigation={secondaryNavigation}>
        <div className="flex flex-row justify-center"></div>
        <div className="sm:flex w-full justify-between  items-start">
          <input
            type="text"
            placeholder="Search by product title"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border-2 border-gray-500 w-24 sm:ml-8 ml-2 w-64 h-10 rounded-lg mb-4 mt-2"
          />
          <div className="w-96 mr-4 flex justify-center">
            <div className="flex flex-col w-32">
              <p className="text-[11px] text-gray-700">
                Price Updater (profit %)
              </p>

              <div className="flex items-center">
                {" "}
                <input
                  type="text"
                  placeholder=""
                  value={profitPercentage}
                  onChange={(e) => {
                    if (e.target.value[0] !== "-" && isNaN(e.target.value)) {
                      globalAlert("Please enter a valid number");
                      return;
                    }
                    setProfitPercentage(e.target.value);
                  }}
                  className="border-2 border-gray-500 w-32  rounded-lg mb-4 mt-2"
                />
                <button
                  className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2 mr-2"
                  onClick={() => {
                    //update the profit percentage
                    setUpdating(true);
                    toast.info("Updating prices, this may take a while...");
               
                    updateStoreProductPrices({
                      store_id: user.store_id,
                      profitPercentage,
                    }).then((res) => {
                      if (res) {
                        setFetchProducts(!fetchProducts);
                        toast.success("Prices updated successfully");
                        
                      } else {
                        toast.error("Something went wrong while updating the prices, please report this to admin")
                      }
                      setUpdating(false);
                    });
                  }}
                >
                  {updating ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center ">
          <div className="w-full overflow-x-auto">
            <div className="table-container">
              <div className="w-full mx-auto">
                <Table className="mb-4 border" hoverable>
                  <Table.Head>
                    {columns.map((column) => (
                      <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width p-4">
                        {
                          <div className="h-4">
                            {!isLoading && (
                              <a className="flex">
                                {column.HeaderIcon}
                                {column.Header}
                              </a>
                            )}
                          </div>
                        }
                      </Table.HeadCell>
                    ))}

                    <Table.HeadCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white fixed-width p-4"></Table.HeadCell>
                  </Table.Head>

                  <Table.Body className="divide-y ">
                    {isLoading ? (
                      Array.from({ length: 8 }).map((_, index) => (
                        <Table.Row key={index}>
                          {columns.map((column, colIndex) => (
                            <Table.Cell className="p-6 " key={colIndex}>
                              <Skeleton className="sm:w-full w-32" count={1} />
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))
                    ) : (
                      <>
                        {filterProducts(products, searchQuery)?.map(
                          (p, index) => (
                            <Table.Row className={"bg-white"}>
                              <Table.Cell className=" p-4  h">
                                <Skeleton
                                  width={60}
                                  height={60}
                                  count={1}
                                  enableAnimation
                                />
                              </Table.Cell>

                              <Table.Cell className="p-4">
                                <p className="">{p.product_title}</p>
                              </Table.Cell>

                              <Table.Cell className="p-4">
                                {p.isPriceList ? (
                                  <p onClick={
                                    () => {
                                     setOpenExcelLoader(p.id);
                                    }
                                  } className="text-indigo-600">
                                    {p?.priceList?.price_list_name}
                                  </p>
                                ) : (
                                  <p className="">
                                    {parseFloat(p.product_price).toFixed(2)}CAD
                                  </p>
                                )}
                              </Table.Cell>
                              {p.isPriceList ? (
                                <Table.Cell className="p-4">
                                  <p className="text-indigo-600"
                                    onClick={
                                      () => {
                                        // get the priceList id from the reference
                                        let priceListReference = p.storeProductPriceListRef;
                  
                                        nav("/priceListLoader/" + priceListReference.id);
                                      }
                                    }
                                  >
                                    {p?.priceList?.price_list_name}
                                  </p>
                                </Table.Cell>
                              ) : (
                                <Table.Cell className="p-4">
                                  {editPrice === p.id ? (
                                    <>
                                      <div className="relative  rounded-md shadow-sm ">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                          <span className="text-gray-500 sm:text-sm">
                                            $
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          name="price"
                                          id="price"
                                          defaultValue={p.store_product_price}
                                          onChange={(e) => {
                                            let temp = [...products];
                                            let index = temp.findIndex(
                                              (x) => x.id === p.id
                                            );

                                            temp[index].store_product_price =
                                              e.target.value;
                                            setProducts(temp);
                                          }}
                                          className="block w-24 rounded-md border-0 py-1.5 pl-7  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          placeholder="0.00"
                                          aria-describedby="price-currency"
                                        />
                                      </div>
                                      <button
                                        className="bg-gray-500 w-24 hover:bg-gray-700 text-white font-bold rounded "
                                        onClick={() => {
                                          setEditPrice("");
                                          handleUpdateProductPrice(p);
                                        }}
                                      >
                                        Save
                                      </button>
                                    </>
                                  ) : (
                                    <div className="flex items-center">
                                      <p className=" relative">
                                        <a className=" text-xs text-gray-600 font-bold">
                                          {" "}
                                          {parseFloat(p.store_product_price).toFixed(2)}CAD
                                        </a>
                                      </p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 mb-4 ml-1 cursor-pointer"
                                        onClick={() => {
                                          setEditPrice(p.id);
                                        }}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </Table.Cell>
                              )}

                              <Table.Cell className="p-4">
                                <p className="">
                                  {p.isAvailable ? (
                                    <a className="text-green-600">Available</a>
                                  ) : (
                                    <a className="text-red-600">
                                      Not Available
                                    </a>
                                  )}
                                </p>
                              </Table.Cell>
                              <Table.Cell className="p-4">
                                <p className="">{p.product_category}</p>
                              </Table.Cell>
                              <Table.Cell className="p-4">
                                <p className="">
                                  {p.creator === "Cachet Admin"
                                    ? "Cachet Blinds"
                                    : p.manufacturer_name}
                                </p>
                              </Table.Cell>
                              <Table.Cell className="p-4">
                                <button
                                  onClick={() => {
                                    removeProductFromInventory(
                                      user.store_id,
                                      p.id
                                    ).then((res) => {
                                      if (res) {
                                        setFetchProducts(!fetchProducts);
                                      } else {
                                        alert(
                                          "Something went wrong while removing the product, please report this to admin"
                                        );
                                      }
                                    });
                                  }}
                                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                                  type="button"
                                >
                                  Remove
                                </button>
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </>
                    )}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </StoreDashboardTemplate>
    </>
  );
}
