import React, { useState, useEffect } from "react";
import { fetchManufacturerProductVariations, fetchStoreProducts } from "../../utils/globalFunctions";
import Skeleton from "react-loading-skeleton";
import { Button, Checkbox, Table } from "flowbite-react";
import { updateAdminProduct,fetchManufacturerProducts ,createProduct,getPriceList,getProduct,getPriceListByRef} from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useUser } from "../../utils/context";
import { useParams } from "react-router-dom";
export default function ProductEdit({manufacturer}) {

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();
  const {id} = useParams()
  const [openSnackbar, closeSnackbar] = useSnackbar({
    position: 'top-center',
    style: {


      color: 'white',
      backgroundColor: '#2c323b',
      fontSize: '16px',
      textAlign: 'center',

      borderRadius: '8px',
      opacity:'1',
      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',


      
   
      fontWeight:'500'



    },

  })



  const [newProductName, setNewProductName] = useState('');
  const [newProductPrice, setNewProductPrice] = useState('');
  const [newProductCategory, setNewProductCategory] = useState('');
  const [priceList,setPriceList] = useState({})
  const [selectedPriceList,setSelectedPriceList] = useState({})
  const [selectedRule,setSelectedRule] = useState('Per Metric')


  // Existing useEffect and other functions...



  useEffect(() => {
    fetchManufacturerProductVariations(user.manufacturerID).then((data) => {
      console.log(data)
        setProducts(data);
        setIsLoading(false);
        });

        getProduct(id).then((data) => {
           console.log(data)
            setNewProductName(data.product_title)
            setNewProductCategory(data.product_category)
            if(data.isPriceList){
                setSelectedRule('Width/Height Excel Sheet')
                let priceListRef = data.priceListRef
                getPriceListByRef(priceListRef).then((data) => {
      
                    setSelectedPriceList(data)
                }
                );



            }else if(data.isMetric){
                setSelectedRule('Per Metric')
                setNewProductPrice(data.product_price)
            }else{
                setSelectedRule('Per Unit')
                setNewProductPrice(data.product_price)
            }
            console.log(data.variations)
            for (let i = 0; i < data.variations.length; i++) {
              setSelectedProducts((prev) => [...prev, data.variations[i].id]);
              }

        }
        );
        

    }, []);
  
    console.log(selectedProducts)

  const handleCreateProduct = () => {
    // Validate input data
    if (!newProductName || !newProductCategory ) {
      alert("Please enter valid product details");
      return;
    }


    console.log(selectedPriceList)

    if(selectedRule === 'Width/Height Excel Sheet' && !selectedPriceList.id){
        alert("Please select a price list");
        return;
    }
    
    if (selectedRule === 'Per Metric' || selectedRule === 'Per Unit'){
        if(!newProductPrice){
            alert("Please enter valid product price");
            return;
        }
    }

    console.log(newProductCategory)

    let newProduct = {
      name: newProductName,
      isPriceList: selectedRule === 'Width/Height Excel Sheet' ? true : false,
      isMetric: selectedRule === 'Per Metric' ? true : false,
      category: newProductCategory,
      manufacturer: user.manufacturerID,
      customizations: selectedProducts
    };

    if(selectedRule === 'Per Metric' || selectedRule === 'Per Unit'){
        newProduct.price = newProductPrice;
    }


   


    updateAdminProduct(newProduct,selectedPriceList.ref,id).then((res) => {
        if(res){
            openSnackbar(
                'Product Updated Successfully'
            )
        }else{
            openSnackbar(
                'Something went wrong, please report this to admin'
            )
        }
        }
    );
    // Reset form fields




    // Optionally navigate to another page or show success message
  };
  const nav = useNavigate();


  useEffect(() => {
    fetchManufacturerProductVariations(user.manufacturerID).then((data) => {
      setProducts(data);
      setIsLoading(false);
    });

    //TODO: Fetch Price List
    getPriceList("dnLLP341yLe4HvKOXd8X",user.manufacturerID).then((data) => {
        setPriceList(data);
    });


  }, []);

  console.log(selectedPriceList)

  return (
    <>
      <div className="pt-12 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Create Products</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => {
                nav("/products");
              }}
            >
              Products
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => {
                nav("/customizations");
              }}
            >
              Customizations
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => {
                nav("/create-product");
              }}
            >
              Create Product
            </button>
          </div>
        </div>
      </div>

              <div className="sm:w-1/2 w-full border mx-auto min-h-[500px] mt-8 rounded-lg">
              <h2 className="text-xl font-bold mb-4 text-center mt-2">New Product</h2>
              <div className="md:pl-24 md:pr-24 pl-8 pr-8">


              <input
          className="border p-2 mb-2 w-full"
          placeholder="Product Name"
          value={newProductName}
          onChange={(e) => setNewProductName(e.target.value)}
        />
        


        <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">Pricing</legend>
              <p className="mt-1 text-sm leading-6 text-gray-600">Please select your pricing rule.</p>
              <div className="mt-2 space-y-2">
              <div className="flex items-center gap-x-3">
                  <input
                    id="push-everything"
                    name="push-notifications"
                    type="radio"
                    checked={selectedRule === 'Per Unit'}
                    
                    onChange={(e) => setSelectedRule("Per Unit")}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Per Unit
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-everything" 
                    name="push-notifications"
                    type="radio"
                    checked = {selectedRule === 'Per Metric'}
                    onChange={(e) => setSelectedRule("Per Metric")}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                    Width/Height
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    id="push-email"
                    name="push-notifications"
                    type="radio"
                    checked = {selectedRule === 'Width/Height Excel Sheet'}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={(e) => setSelectedRule("Width/Height Excel Sheet")}
                  />
                  <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Excel Sheet (Width/Height)
                  </label>
                </div>

              </div>
            </fieldset>

            {
                selectedRule === 'Width/Height Excel Sheet' ?
  
<select
            className="border p-2 mb-2 w-full mt-2"
            value={selectedPriceList.id}
            onChange={(e)=>{
               setSelectedPriceList(priceList.find(
                    price => price.id === e.target.value
                ))
            }}
            >
            <option value="">Select Price List</option>
            {
                priceList.map(
                    (price,index) => (
                        <option key={index} value={price.id}>{price.price_list_name}</option>
                    )
                )
            }
           </select> 
                :
                <input
                className="border p-2 mb-2 w-full mt-2"
                placeholder="Product Price"
                value={newProductPrice}
                onChange={(e) => setNewProductPrice(e.target.value)}
                type="text"
              />
            }


        <select
            className="border p-2 mb-2 w-full"
            value={newProductCategory}
            onChange={(e) => setNewProductCategory(e.target.value)}
            >
                          <option value="">Select Category</option>
              {
                  manufacturer.categories.map(
                      (category,index) => (
                          <option key={index} value={category}>{category}</option>
                      )
                  )

              }
           </select> 
      

        <input
          className="border p-2 mb-4 w-full"
          placeholder="Manufacturer"
          value={user.manufacturerID} 
        />



        

      <p>Choose Customizations</p>
        <div>
            {
                products.map(
                    (product,index) => (
                        <div key={index} className="flex justify-between items-center border p-2 mb-2">
                            <div className="flex items-center">
                                <Checkbox
                                    checked={selectedProducts.includes(product.id)}

                      

                                    className="
                                    bg-gray-600"
                                    onChange={(e) => {
                                      console.log(product)
                                        if(e.target.checked)
                                            setSelectedProducts([...selectedProducts,product.id])
                                        else
                                            setSelectedProducts(selectedProducts.filter(id => id !== product.id))
                                    }}
                                />
                    
                                <h3 className="text-sm ml-2">{product.variation_title}</h3>
                                {
                                    selectedProducts.findIndex(
                                        id => id === product.id
                                    ) + 1 ?
                                    <p className="text-xs ml-2">({selectedProducts.findIndex(
                                        id => id === product.id
                                    ) + 1})</p>
                                    :
                                    null
                                    
                                }
                            </div>
            
                        </div>
                    )
                )
            }
        </div>
        <div className="flex w-full justify-center">

        <button
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-2 mt-2"
          onClick={handleCreateProduct}
        >
          Update Product
        </button>
        </div>

              </div>



              </div>

      <div></div>
    </>
  );
}
