import React, { useEffect, useState } from 'react';
import { getGlobalRules, updateGlobalRule, deleteManufactureRule } from '../../utils/globalFunctions';
import { globalSuccess,getAllGlobalVariations } from '../../utils/globalFunctions';
import { set } from 'lodash';

export default function NewRules({ user, manufacturer }) {
  const [savedRules, setSavedRules] = useState(null);
  const [fractionValues, setFractionValues] = useState({});
  const [deductionValues, setDeductionValues] = useState({});
  const [isRuleMap, setIsRuleMap] = useState({});


  console.log(savedRules);
  useEffect(() => {

    getRules();

    getAllGlobalVariations(user.manufacturerID).then((data_) => {
      console.log(data_);
      let map = {};
      let map1 = {};
      let history = [];
      data_.forEach((item) => {  
        
        console.log("item", item);
        history.push(item);
        // check if the key is already in the map
        if (map[item.variation_title]) {
          // find the object in the history array
          let obj = history.find(
            (o) => o.variation_title === item.variation_title
          );
          // if the object is found, check the time createdAt and update the map with the latest object

          if (obj.isDeleted && !item.isDeleted) {
            console.log("item", item);

            map[item.variation_title] = item?.key
              ? item.key
              : "Customizations";
            map1[item.variation_title] = item?.isRule
              ? item.isRule
              : false;

            // remove the old object from the history array
            history = history.filter(
              (o) => o.variation_title !== item.variation_title
            );

            // add the new object to the history array
            history.push(item);
          }
        } else {
          if (!item?.key) {
            console.log("item", item);
          }

          map[item.variation_title] = item?.key
            ? item.key
            : item.variation_title;
          map1[item.variation_title] = item?.isRule ? item.isRule : false;
        }
      });
      setIsRuleMap(map1);
    })


  }, []);
  

  const getRules = () => {
    getGlobalRules(user.manufacturerID).then((res) => {
      setSavedRules(res);

      const loadedFractionValues = {};
      const loadedDeductionValues = {};
      res.forEach((rule) => {
        if(rule.metricSystem === 'inch'){
          if (rule.fractionValues) {
            loadedFractionValues[rule.ruleName] = rule.fractionValues;
          }
        }else{
          if(rule.deductions){
            loadedDeductionValues[rule.ruleName] = rule.deductions;
          }
        }

      });


      setFractionValues(loadedFractionValues);
      setDeductionValues(loadedDeductionValues);

    });
  };


  const handleFractionChange = (ruleName, key, part, value) => {
    // Allow only numbers
    const numericValue = value.replace(/[^0-9]/g, '');
    setFractionValues((prevValues) => ({
      ...prevValues,
      [ruleName]: {
        ...prevValues[ruleName],
        [key + part]: numericValue,
      },
    }));
  };

  const handleCmChange = (rule, key, value) => {
    // Allow only numbers and decimal points
    const numericValue = value.replace(/[^0-9.]/g, '');
    setDeductionValues((prevValues) => ({
      ...prevValues,
      [rule]: {
        ...prevValues[rule],
        [key]: numericValue,
      },
    }));
  };

  
  const onClickNotApplicable = (ruleName, key, cmOrInch) => (e) => {
    console.log(e.target.checked);
    if(cmOrInch === 'inch'){
      if (e.target.checked) {
        setFractionValues((prevValues) => ({
          ...prevValues,
          [ruleName]: {
            ...prevValues[ruleName],
            [key + 'Whole']: 'none',
            [key + 'Fraction']: 'none',
          },
        }));
      } else {
        setFractionValues((prevValues) => ({
          ...prevValues,
          [ruleName]: {
            ...prevValues[ruleName],
            [key + 'Whole']: '',
            [key + 'Fraction']: '0',
          },
        }));
      }
    }else{
      if(e.target.checked){
        setDeductionValues((prevValues) => ({
          ...prevValues,
          [ruleName]: {
            ...prevValues[ruleName],
            [key]: 'none',
          },
        }));

      }else{
        setDeductionValues((prevValues) => ({
          ...prevValues,
          [ruleName]: {
            ...prevValues[ruleName],
            [key]: 0,
          },
        }));

      }
    }

  }
  const renderInchInput = (ruleName, key) => {
    const wholeValue = fractionValues[ruleName]?.[key + 'Whole'] ?? '';
    const fractionValue = fractionValues[ruleName]?.[key + 'Fraction'] ?? '';
    
    return (
      <div className="flex space-x-2 items-center">
        <div className="flex flex-col">
          <label htmlFor={key} className="text-sm font-medium text-gray-600">
            N/A
          </label>
          <input
            type="checkbox"
            id="notApplicable"
            name="notApplicable"
            onClick={onClickNotApplicable(ruleName, key, 'inch')}
            checked={wholeValue === 'none'}
          />
        </div>
        <input
          type="text"
          value={wholeValue}
          disabled={wholeValue === 'none'}
          onChange={(e) => handleFractionChange(ruleName, key, 'Whole', e.target.value)}
          placeholder="Inches"
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-2/3"
        />
        <select
          value={fractionValue}
          disabled={fractionValue === 'none'}
          onChange={(e) => handleFractionChange(ruleName, key, 'Fraction', e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-1/3"
        >
          {/* Options */}
        </select>
      </div>
    );
  };

  const handleSave = (rule) => {
    if (manufacturer.metricSystem === 'inch') {
      const updatedFractionValues = fractionValues[rule.ruleName];

      rule.deductions = {
        bottomBar: updatedFractionValues.bottomBarWhole === "none" ? "none" : parseFloat(updatedFractionValues.bottomBarWhole || 0) + parseFloat(updatedFractionValues.bottomBarFraction || 0),
        bottomTube: updatedFractionValues.bottomTubeWhole === "none" ? "none" : parseFloat(updatedFractionValues.bottomTubeWhole || 0) + parseFloat(updatedFractionValues.bottomTubeFraction || 0),
        cassetteCut: updatedFractionValues.cassetteCutWhole === "none" ? "none" : parseFloat(updatedFractionValues.cassetteCutWhole || 0) + parseFloat(updatedFractionValues.cassetteCutFraction || 0),
        fabricWidth: updatedFractionValues.fabricWidthWhole === "none" ? "none" : parseFloat(updatedFractionValues.fabricWidthWhole || 0) + parseFloat(updatedFractionValues.fabricWidthFraction || 0),
        tubeCut: updatedFractionValues.tubeCutWhole === "none" ? "none" : parseFloat(updatedFractionValues.tubeCutWhole || 0) + parseFloat(updatedFractionValues.tubeCutFraction || 0),
        fabricLength: updatedFractionValues.fabricLengthWhole === "none" ? "none" : parseFloat(updatedFractionValues.fabricLengthWhole || 0) + parseFloat(updatedFractionValues.fabricLengthFraction || 0),

    };

      rule.fractionValues = updatedFractionValues;
    }else{
      rule.deductions = deductionValues[rule.ruleName];
    }

    updateGlobalRule(rule).then((res) => {
      if (res) {
        globalSuccess('Rule updated successfully');
      }
    });
  };

  const renderCmInput = (ruleName, key) => {
    const cmValue = deductionValues[ruleName]?.[key] ?? '';

    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          <label htmlFor={key} className="text-sm font-medium text-gray-600">
            N/A
          </label>
          <input
            type="checkbox"
            id="notApplicable"
            name="notApplicable"
            onClick={onClickNotApplicable(ruleName, key, 'cm')}
            checked={cmValue === 'none'}
          />
        </div>
        <input
          type="text"
          value={cmValue}
          disabled={cmValue === 'none'}
          onChange={(e) => handleCmChange(ruleName, key, e.target.value)}
          placeholder="cm"
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
    );
  }

  console.log(isRuleMap);
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Production Manufacturer Cut Rules</h1>
        {savedRules ? (
          savedRules.map((rule, index) => (
            <div key={index} className="mb-8 border-b  shadow-lg p-8">
              <h2 className="text-xl font-semibold text-gray-700 mb-4">
                {rule.ruleName} <span className="text-gray-500">({rule.product_type})</span>
              </h2>
              <div className='border-b border-t'> 
              {
                // show the rules
                Object.keys(rule.rules).sort().map((key) => (
             isRuleMap[key] &&
                  <div key={key} className="flex flex-col">
                    <label htmlFor={key} className="text-sm font-medium text-gray-600">
                      {key} : {rule.rules[key]}
                    </label>
           
                  </div>
                ))
              }
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4">
                {Object.keys(rule.deductions).map((key) => (
                  <div key={key} className="flex flex-col">
                    <label htmlFor={key} className="text-sm font-medium text-gray-600">
                      {key}
                    </label>
                    {manufacturer.metricSystem === 'inch' ? (
                      renderInchInput(rule.ruleName, key)
                    ) : (
                      renderCmInput(rule.ruleName, key)
                    )}
                  </div>
                ))}
              </div>
              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() => handleSave(rule)}
                  className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
                >
                  Update Rule
                </button>
                <button
                  onClick={() => {
                    deleteManufactureRule(rule.id).then((res) => {
                      if (res) {
                        globalSuccess('Rule has been deleted successfully');
                        getRules();
                      }
                    });
                  }}
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                >
                  Delete Rule
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No rules found.</p>
        )}
      </div>
    </div>
  );
}
