import { AppStoreLink } from './AppStoreLink'
import { CircleBackground } from './CircleBackground'
import { Container } from './Container'

export function CallToAction() {
  return (
    <section
      id="get-free-shares-today"
      className="relative overflow-hidden bg-gray-900 py-20 sm:py-28"
    >
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground color="#fff" className="animate-spin-slower" />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-md sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
          Ready to Elevate Your Business?
          </h2>
          <p className="mt-4 text-lg text-gray-300">
          Join the growing community of manufacturers and dealers who are transforming their businesses with Suppy Circle. Start your free trial today and see the difference.
          </p>
          <div className="mt-8 flex justify-center">
            
            <button
              onClick={() => {
                window.location.href = '/login'
              }}
              className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-cyan-500 rounded-lg shadow-sm hover:bg-cyan-600"
            >
              Get Started
            </button>
          </div>
        </div>
      </Container>
    </section>
  )
}
