import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { db } from '../utils/firebase';
import {savePriceList} from '../utils/globalFunctions';



const ExcelLoader = ({user,name,setUploaded}) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // get the columns headers
        const colHeaders = [];
        for (let i = 1; i <= 26; i++) {
          const cell = sheet[String.fromCharCode(64 + i) + '1'];
          if (cell) {
            colHeaders.push(cell.v);
          }
        }
        console.log(colHeaders)

        const rowHeaders = [];
        for (let i = 1; i <= 26; i++) {
          const cell = sheet['A' + i];
          if (cell) {
            rowHeaders.push(cell.v);
          }
        }

        console.log(sheet)

        const list = {};

        for (let i = 0; i < colHeaders.length; i++) {
            let col = colHeaders[i];
            let char = String.fromCharCode(65 + i + 1);
            for (let j = 0; j <= rowHeaders.length + 1; j++) {
                let row = rowHeaders[j];
                console.log(char + (j + 2))
                const cell = sheet[char + (j + 2)];
                console.log(cell)
                if (cell) {
                    list[`${col}-${row}`] = cell.v;
                }
            }
        }

        let toBeSaved = {
            price_list_name: name,
            priceMap: list,
            columns: colHeaders,
            rows: rowHeaders,
            isDeleted: false,
        }

        console.log(toBeSaved)


        

     let res =   await savePriceList("dnLLP341yLe4HvKOXd8X",user.manufacturerID,toBeSaved);


    if(res){
        alert('Data uploaded successfully');

    }else{
        alert('Something went wrong, please try again');
    }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default ExcelLoader;
