import { Container } from './Container'

const faqs = [
  [
    {
      question: 'What is Suppy Circle?',
      answer:
        'Suppy Circle is an all-in-one platform designed for manufacturers and dealers to manage orders, track inventory, and scale operations efficiently. It provides tools for creating custom order forms, managing inventory in real-time, and setting dealer-specific pricing.',
    },
    {
      question: 'How does Suppy Circle benefit my business?',
      answer:
        'Suppy Circle streamlines your operations by centralizing order management, inventory tracking, and pricing controls in one easy-to-use platform, allowing you to focus on growing your business.',
    },
    {
      question: 'Can I customize the order forms on Suppy Circle?',
      answer:
        'Yes, Suppy Circle allows you to create and customize order forms tailored to your specific business needs, ensuring a seamless experience for your dealers.',
    },
  ],
  [
    {
      question: 'How does inventory management work on Suppy Circle?',
      answer:
        'Our platform provides real-time inventory tracking, helping you monitor stock levels, avoid shortages, and make informed decisions about reordering and supply chain management.',
    },
    {
      question: 'Can dealers set their own pricing on Suppy Circle?',
      answer:
        'Yes, dealers can set their own pricing on top of the base prices you provide, giving them the flexibility to resell the products.',
    },
    {
      question: 'What kind of businesses can benefit from Suppy Circle?',
      answer:
        'Suppy Circle is designed for manufacturers of all sizes, from small businesses to large enterprises. Whether you`re managing a few products or a large catalog, our platform scales to meet your needs.',
    },
  ],
  [
    {
      question: 'Is Suppy Circle extendable?',
      answer:
        'Absolutely! Suppy Circle features an extension marketplace where you can add functionalities such as digital catalogs, invoicing, payment processing, and shipping modules to further customize your platform.',
    },
    {
      question: 'What support options are available?',
      answer:
        'Security is our top priority. Suppy Circle uses state-of-the-art encryption and follows industry best practices to ensure that your data is secure and protected at all times.',
    },
    {
      question: ' Can Suppy Circle integrate with other tools?',
      answer:
        'Yes, Suppy Circle we integrate with a variety of tools and services such as accounting tools to help you streamline your operations and improve efficiency.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="border-t border-gray-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-gray-900"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            If you have anything else you want to ask,{' '}
            <a
              href="mailto:info@example.com"
              className="text-gray-900 underline"
            >
              reach out to us
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
