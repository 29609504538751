import React, { useState, useEffect, useMemo } from "react";
import {
  fetchManufacturerProducts,
  updateStoreProduct,
  deleteManufacturerProduct,
  notifyManufacturerSubscribers,
} from "../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import Skeleton from "react-loading-skeleton";

export default function Products({ user }) {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const navigate = useNavigate();

  const rowsPerPage = 6;

  // Fetch unformatted products
  useEffect(() => {
    fetchManufacturerProducts(user.manufacturerID).then((data) => {
      setProducts(data);
      setFilteredProducts(data);
      setIsLoading(false);
      setIsLastPage(data.length <= rowsPerPage);
    });
  }, [user.manufacturerID]);

  // Update last page status when filteredProducts or currentPage changes
  useEffect(() => {
    checkIsLastPage();
  }, [filteredProducts, currentPage]);

  // Handle making a product available
  const handleMakeAvailable = (product) => {
    const updatedProduct = { ...product, isAvailable: true };
    updateProductAvailability(updatedProduct);
  };

  // Handle making a product unavailable
  const handleMakeUnavailable = (product) => {
    const updatedProduct = { ...product, isAvailable: false };
    updateProductAvailability(updatedProduct);
  };

  // Update product availability in the backend and state
  const updateProductAvailability = (product) => {
    updateStoreProduct(user.role, user.manufacturerID, product).then((res) => {
      if (!res) {
        alert(
          "Something went wrong while updating the availability, please report this to admin"
        );
      } else {
        setProducts((prevProducts) =>
          prevProducts.map((p) => (p.id === product.id ? product : p))
        );
        setFilteredProducts((prevProducts) =>
          prevProducts.map((p) => (p.id === product.id ? product : p))
        );
      }
    });
  };

  // Handle deleting a product
  const handleDeleteProduct = (product) => {
    notifyManufacturerSubscribers(
      user.manufacturerID,
      "Product Update",
      `A product has been deleted by the manufacturer. ${product.product_title} is no longer available for purchase.`
    );
    deleteManufacturerProduct(product.id).then((res) => {
      if (res) {
        setProducts((prevProducts) =>
          prevProducts.filter((prod) => prod.id !== product.id)
        );
        setFilteredProducts((prevProducts) =>
          prevProducts.filter((prod) => prod.id !== product.id)
        );
      }
    });
  };

  // Handle search input changes
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = products.filter((product) =>
      product.product_title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
    setCurrentPage(0);
  };

  // Check if the current page is the last page
  const checkIsLastPage = () => {
    setIsLastPage(
      (currentPage + 1) * rowsPerPage >= filteredProducts.length
    );
  };

  // Navigate to the next page
  const handleNextPage = () => {
    if (!isLastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Navigate to the previous page
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Define table columns
  const columns = [
    { Header: "Image", accessor: "image" },
    { Header: "Title", accessor: "product_title" },
    { Header: "Manufacturer Price", accessor: "product_price" },
    { Header: "Availability", accessor: "isAvailable" },
    { Header: "Category", accessor: "product_category" },
    { Header: "Manufacturer", accessor: "manufacturer_name" },
    { Header: "Actions", accessor: "actions" },
  ];

  // Format products data before rendering
  const formattedProducts = useMemo(() => {
    return filteredProducts.map((product) => ({
      ...product,
      image: <Skeleton width={60} height={60} enableAnimation />,
      product_price: product.isPriceList ? (
        <a
          onClick={() =>
            navigate(`/add-ons/priceListLoader/${product?.priceList?.id}`)
          }
          className="text-indigo-600 cursor-pointer"
        >
          {product?.priceList?.price_list_name}
        </a>
      ) : (
        `$${product.product_price}`
      ),
      isAvailable: product.isAvailable ? (
        <a
          onClick={() => handleMakeUnavailable(product)}
          className="text-green-600 cursor-pointer"
        >
          Available
        </a>
      ) : (
        <a
          onClick={() => handleMakeAvailable(product)}
          className="text-red-600 cursor-pointer"
        >
          Not Available
        </a>
      ),
      actions: (
        <div className="flex items-center">
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded ml-2"
            onClick={() => handleDeleteProduct(product)}
          >
            Delete
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded ml-2"
            onClick={() => navigate(`/edit/product/${product.id}`)}
          >
            View
          </button>
        </div>
      ),
    }));
  }, [filteredProducts, navigate]);

  // Get paginated data
  const paginatedData = useMemo(() => {
    const startIndex = currentPage * rowsPerPage;
    return formattedProducts.slice(startIndex, startIndex + rowsPerPage);
  }, [formattedProducts, currentPage]);

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Manufacturer Products</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => navigate("/products")}
            >
              Products
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => navigate("/customizations")}
            >
              Customizations
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs ml-2"
              onClick={() => navigate("/create-product")}
            >
              Create Product
            </button>
          </div>
        </div>
      </div>

      <div className="pt-4 flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <input
            type="text"
            placeholder="Search by title"
            className="bg-white text-xs p-2 w-full mt-1 rounded border"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
        <div className="w-full overflow-x-auto">
          <div className="table-container">
            <div className="w-full mx-auto">
              <div className="flex justify-between mb-4">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                  onClick={handleNextPage}
                  disabled={isLastPage}
                >
                  Next
                </button>
              </div>
              <TableComponent
                columns={columns}
                isLoading={isLoading}
                data={paginatedData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}