// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestor,initializeFirestore,CACHE_SIZE_UNLIMITED, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { enableIndexedDbPersistence } from "firebase/firestore";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMkCaHEBUte2-_s6s5ZFR1dlpJctnGMtM",
  authDomain: "cachet-blinds.firebaseapp.com",
  projectId: "cachet-blinds",
  storageBucket: "cachet-blinds.appspot.com",
  messagingSenderId: "272860659402",
  appId: "1:272860659402:web:510c91f9e8ab6cb448be33",
  measurementId: "G-ZY89KEE7BC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db =
  initializeFirestore(app, {
    experimentalForceLongPolling: true, // Required for some browsers

  });


export  const storage = getStorage(app);

export const auth = getAuth(app);
// const messaging = getMessaging();

export const requestForToken = () => {
  // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
  // when sending message requests to different push services
  // return getToken(messaging, { vapidKey: `BJq7UAvKE08jKCMd5I4LplCqZRb35XIzqluOHFqiMIdOrXAl9jQb3zbAZSxtjz9uJOz2Exz14xt7t_oDm19ed30` }) //to authorize send requests to supported web push services
  //     .then((currentToken) => {
  //         if (currentToken) {
            

  //             // if(localStorage.getItem('fcmToken') && currentToken !==localStorage.getItem('fcmToken')){
  //             //     localStorage.setItem('fcmToken', currentToken);

  //             // }

  //             // else if(!localStorage.getItem('fcmToken')){
  //             //     localStorage.setItem('fcmToken', currentToken);

  //             // }

  //             return currentToken;


  //         } else {
  //             console.log('No registration token available. Request permission to generate one.');
  //         }
  //     })
  //     .catch((err) => {
  //         console.log('An error occurred while retrieving token. ', err);
  //     });
};

export const saveNotificationTokenToUser = async() => {
  let token = await requestForToken()
  console.log("token",token)
  if (auth.currentUser) {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        let user = userDoc.data()
        console.log("user",user)
          if (token) {
              const storeDocRef = doc(db, 'Stores', user.store_id);
              await setDoc(storeDocRef, {
                  fcmTokens: {
                      [token]: true
                  },
              }, { merge: true });
            


   
          }
      }
  }
}





export const onMessageListener = () =>
  new Promise((resolve) => {
    // onMessage(messaging, (payload) => {
    //   resolve(payload);
    // });
  }
);


// callable cloud functions
const functions = getFunctions(app);
// enableIndexedDbPersistence(db)
//   .then(() => console.log("Offline data persistence is enabled."))
//   .catch(err => {
//     if (err.code === 'failed-precondition') {
//       // Handle the error of multiple tabs open at once
//     } else if (err.code === 'unimplemented') {
//       // Handle the lack of browser support for IndexedDB
//     }
//   });
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}


export const getMissingProducts = httpsCallable(functions, 'getMissingProducts',{
  timeout: 540000, // 30 seconds
});


export const getStoreStats = httpsCallable(functions, 'getStoreStats',{
  timeout: 70000, // 30 seconds
});

export const getTop3SellingStoreProducts = httpsCallable(functions, 'getTop3SellingStoreProducts',{
  timeout: 70000, // 30 seconds
});

export const getTrendingProducts = httpsCallable(functions, 'getTrendingProducts',{
  timeout: 70000, // 30 seconds
});


export const getRecentCustomers = httpsCallable(functions, 'getRecentCustomers',{
  timeout: 70000, // 30 seconds
});

export const updateStoreProductPrices = httpsCallable(functions, 'updateStoreProductPrices',{
  timeout: 540000, // 9 mins
});



export const migrateProducts = httpsCallable(functions, 'migrateProducts',{
  timeout: 70000, // 30 seconds
});

export const sendEmail = httpsCallable(functions, 'sendEmail',{
  timeout: 70000, // 30 seconds
});


export const storeProductSetup = httpsCallable(functions, 'storeProductSetup',{
  timeout: 540000, // 9 mins
});