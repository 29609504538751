import React, { useEffect,useState } from 'react'
import OrderStats from '../../components/OrderStats'
import { getProducts,fetchUpdatesWithUserDetails } from '../../utils/globalFunctions'
import AdminEditor from './AdminEditor'


const UpdatesList = ({ updates }) => {
  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center">Latest Updates</h1>
      <div className="grid gap-8">
        {updates.map((update) => (
          <div key={update.id} className="p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-blue-600 mb-2">{update.title}</h2>
            <p className="text-sm text-gray-500 mb-4">
              Version: <span className="font-semibold">{update.version}</span>
            </p>
            <div
              className="prose mb-4"
              dangerouslySetInnerHTML={{ __html: update.contentHTML }}
            />
            <h3 className="text-xl font-semibold text-gray-700 mb-2">Users</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {update.users &&
                update.users.map((user, index) =>
                  user ? (
                    <div
                      key={index}
                      className="p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition duration-300"
                    >
                      {/* Render user details */}
                      <h4 className="text-lg font-semibold text-gray-800">
                        {user.role ? user.role.charAt(0).toUpperCase() + user.role.slice(1) : "Unknown Role"}
                      </h4>
                      <p className="text-sm text-gray-600">Email: {user.email || "No Email Provided"}</p>
                      {user.manufacturer && (
                        <p className="text-sm text-gray-600">
                         Connected Manufacturer: {user.manufacturer}
                        </p>
                      )}
                      {user.manufacturerID && (
                        <p className="text-sm text-gray-600">
                          Manufacturer: {user.manufacturerID}
                        </p>
                      )}
                      {user.store_id && (
                        <p className="text-sm text-gray-600">Store ID: {user.store_id}</p>
                      )}
                      {user?.createdAt && (
                        <p className="text-sm text-gray-600">
                          Created At:{" "}
                          {new Date(user.createdAt.seconds * 1000).toLocaleDateString()}
                        </p>
                      )}

                      {/* Highlight missing fields */}
                      <div className="mt-2 text-xs text-red-500">
                        {(!user.role || !user.email) && (
                          <p>
                            <strong>Missing Fields:</strong>{" "}
                            {!user.role && <span>Role, </span>}
                            {!user.email && <span>Email</span>}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="p-4 border border-red-200 bg-red-50 text-red-600 rounded-lg shadow-sm"
                    >
                      <p><strong>Invalid User Data</strong></p>
                      <p className="text-sm">User ID: {update.users[index] ? update.users[index].id : "Unknown"}</p>
                      <p className="text-sm">Data is missing or incomplete for this user.</p>
                    </div>
                  )
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};



export default function Dashboard() {

  const [updates,setUpdates] = useState([])


  useEffect(() => {
   getProducts().then((products)=>{
    console.log(products);
   })


   fetchUpdatesWithUserDetails().then((updates)=>{
    setUpdates(updates)
   })


  }, [])

  console.log(updates)

  return (
    <div>
        <OrderStats />

        <AdminEditor />

        {
          updates && updates.length > 0 &&        
          <UpdatesList updates={updates} />
        }
 
    </div>
  )
}
