import React, { useState, useEffect } from "react";
import {
  getCustomers,
  getCustomerOrders,
  getOrder,
  updateCustomer,
  globalSuccess,
  globalAlert,
  updateStorePhoneNumber,
} from "../../utils/globalFunctions";
import { generateEstimate } from "./pdf-generator";
import { sendEmail } from "../../utils/firebase";

// Template previews (you can replace these with actual image URLs)
const templates = [
  {
    id: "template1",
    name: "Classic Template",
    image: "https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Screenshot%202024-09-19%20at%207.40.57%E2%80%AFPM.png?alt=media&token=6f4e1f56-f0ab-48f3-a66e-ff986c390c09", // Replace with actual image path
    isFree: true,
  },
  {
    id: "template2",
    name: "Modern Template",
    image: "https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Screenshot%202024-09-19%20at%207.41.30%E2%80%AFPM.png?alt=media&token=e816c3a7-6f03-4b9a-81e4-1a3f74284060", // Replace with actual image path
    isFree: true, // Set to false if you want to make it paid in the future
  },
  {
    id: "template3",
    name: "Premium Template",
    image: "https://firebasestorage.googleapis.com/v0/b/cachet-blinds.appspot.com/o/Screenshot%202024-09-19%20at%207.42.07%E2%80%AFPM.png?alt=media&token=7830a686-f009-4d47-ba95-a65979d8692f", // Replace with actual image path
    isFree: true, // This template will be paid in the future
  },
];

export default function BlindsEstimate({ user }) {
  const [customers, setCustomers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("template1");
  const [loading, setLoading] = useState({ customers: true, orders: false });
  const [sendingEmail, setSendingEmail] = useState(false);
  const [loading1, setLoading1] = useState(false);

  // Fetch customers on component mount
  useEffect(() => {
    async function fetchCustomers() {
      try {
        const data = await getCustomers(user.store_id);
        setCustomers(data);
      } catch (error) {
        globalAlert("Error fetching customers.");
      } finally {
        setLoading((prev) => ({ ...prev, customers: false }));
      }
    }
    fetchCustomers();
  }, [user.store_id]);

  // Fetch customer orders when a customer is selected
  const handleCustomerChange = async (customerId) => {
    setSelectedCustomer(customerId);
    if (customerId) {
      setLoading((prev) => ({ ...prev, orders: true }));
      try {
        const data = await getCustomerOrders(customerId);
        setOrders(data);
      } catch (error) {
        globalAlert("Error fetching orders.");
      } finally {
        setLoading((prev) => ({ ...prev, orders: false }));
      }
    } else {
      setOrders([]);
      setSelectedOrder("");
    }
  };

  // Download the estimate
  const handleDownloadEstimate = async () => {
    setLoading1(true);

    if (!selectedCustomer || !selectedOrder) {
      globalAlert("Please select both a customer and an order.");
      setLoading1(false);
      return;
    }
    try {
      const order = await getOrder(selectedOrder);
      await generateEstimate(order, user, selectedTemplate);
      globalSuccess("Estimate generated successfully.");
    } catch (error) {
      console.error(error);
      globalAlert("Error generating estimate.");
    } finally {
      setSelectedOrder("");
      setLoading1(false);
    }
  };

  // Email the estimate
  const handleEmailEstimate = async () => {
    if (!selectedCustomer || !selectedOrder) {
      globalAlert("Please select both a customer and an order.");
      return;
    }

    setSendingEmail(true);
    try {
      const customer = customers.find((c) => c.id === selectedCustomer);
      let toEmail = customer.customerEmail;

      if (!toEmail) {
        toEmail = prompt(
          "Customer does not have an email. Please provide one:"
        );
        if (toEmail) {
          customer.customerEmail = toEmail;
          await updateCustomer({
            store_id: user.store_id,
            customer_id: customer.id,
            data: customer,
          });
        } else {
          globalAlert("Email address is required to send an estimate.");
          setSendingEmail(false);
          return;
        }
      }

      const order = await getOrder(selectedOrder);
      const pdfBlob = await generateEstimate(
        order,
        user,
        selectedTemplate,
        true
      );

      const blobToBase64 = (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result.split(",")[1]);
          };
          reader.onerror = reject;
        });

      const base64Pdf = await blobToBase64(pdfBlob);

   
      if(!user?.storePhoneNumber){
        let phoneNumber = window.prompt("Please enter your store phone number as it will appear on the estimate");
     
        if(phoneNumber){
            // add phone number to the store
           await updateStorePhoneNumber({
                store_id: user.store_id,
                phoneNumber: phoneNumber
            })
            user.storePhoneNumber = phoneNumber;

        }

      }
    
      const phoneNumber = user.storePhoneNumber

      await sendEmail({
        to: toEmail,
        from: user.email, // Ensure the 'from' address is set to your company's email
        subject: `Your Custom Blinds Estimate from ${user.storeName}`,
        sender: user.storeName,
        text: `
      Dear ${customer.customerName},
      
      Thank you for considering ${user.storeName} for your custom blinds needs. Please find your personalized estimate attached to this email.
      
      If you have any questions or require further assistance, please feel free to contact us at ${user.email} or call us at ${user.phoneNumber}.
      
      We appreciate your business and look forward to serving you.
      
      Best regards,
      The ${user.storeName} Team
      ${user.email}
      ${user.phoneNumber}
      ${user.address}
      `,
        html: `
      <p>Dear ${customer.customerName},</p>
      
      <p>Thank you for considering <strong>${user.storeName}</strong> for your custom blinds needs. Please find your personalized estimate attached to this email.</p>
      
      <p>If you have any questions or require further assistance, please feel free to contact us at <a href="mailto:${user.email}">${user.email}</a> or call us at <a href="tel:${phoneNumber}">${phoneNumber}</a>.</p>
      
      <p>We appreciate your business and look forward to serving you.</p>
      
      <p>Best regards,<br>
      <strong>The ${user.storeName} Team</strong><br>
      Email: <a href="mailto:${user.email}">${user.email}</a><br>
      Phone: <a href="tel:${phoneNumber}">${phoneNumber}</a><br>
      </p>
      `,
        attachments: [
          {
            filename: "Blinds Estimate.pdf",
            type: "application/pdf",
            content: base64Pdf,
            encoding: "base64",
          },
        ],
      });
      globalSuccess("Estimate sent successfully.");
    } catch (error) {
      console.error(error);
      globalAlert("Error sending email. Please try again.");
    } finally {
      setSendingEmail(false);
      setSelectedOrder("");
    }
  };

  return (
    <div className="w-full">
      <h1 className="text-2xl font-bold mt-4 mb-4 text-center">
        Blinds Estimate
      </h1>
      <div className="sm:w-1/2 pl-4 pr-4 mx-auto">
        {/* Customer Selection */}
        <p className="font-medium mb-2">Select a customer</p>
        <select
          className="select w-full border-gray-300 py-2 px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={selectedCustomer}
          onChange={(e) => handleCustomerChange(e.target.value)}
        >
          <option value="">Select Customer</option>
          {customers.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.customerName}
            </option>
          ))}
        </select>

        {/* Order Selection */}
        <p className="font-medium mt-4 mb-2">Select an order</p>
        <select
          className="w-full border-gray-300 py-2 px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={selectedOrder}
          onChange={(e) => setSelectedOrder(e.target.value)}
          disabled={loading.orders || !orders.length}
        >
          <option value="">Select Order</option>
          {orders.map((order) => (
            <option key={order.id} value={order.id}>
              Order #{order.id.slice(-4)}
            </option>
          ))}
        </select>

        {/* Template Selection */}
        <p className="font-medium mt-4 mb-2">Select a template</p>
        <div className="space-y-4">
          {templates.map((template) => (
            <label
              key={template.id}
              className={`flex items-center p-2 border rounded-md ${
                !template.isFree ? "opacity-70" : ""
              }`}
            >
              <input
                type="radio"
                name="template"
                value={template.id}
                checked={selectedTemplate === template.id}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                disabled={!template.isFree}
                className="form-radio h-5 w-5 text-blue-600"
              />
              <img
                src={template.image}
                alt={template.name}
                className="w-16 h-24 object-cover ml-4"
                
              />
              <div className="ml-4">
                <p className="font-medium">{template.name}</p>
                {!template.isFree && (
                  <p className="text-sm text-red-500">
                    Premium Template (Paid)
                  </p>
                )}
              </div>
            </label>
          ))}
        </div>

        {/* Actions */}
        <div className="mt-4 space-y-4">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 w-full"
            onClick={handleDownloadEstimate}
            disabled={!selectedCustomer || !selectedOrder || loading1}
          >
            {loading1 ? "Generating Estimate..." : "Download Estimate"}
          </button>

          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 w-full"
            onClick={handleEmailEstimate}
            disabled={sendingEmail || !selectedCustomer || !selectedOrder}
          >
            {sendingEmail ? "Sending Email..." : "Email Estimate"}
          </button>
        </div>
      </div>
    </div>
  );
}
