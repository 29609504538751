import React from "react";
import {Hero} from "../LandingComponents/Hero";
import {PrimaryFeatures} from "../LandingComponents/PrimaryFeatures";
import {SecondaryFeatures} from "../LandingComponents/SecondaryFeatures";
import {CallToAction} from "../LandingComponents/CallToAction";
import {Reviews} from "../LandingComponents/Reviews";
import {Pricing} from "../LandingComponents/Pricing";
import {Faqs} from "../LandingComponents/Faqs";
import NavBar from "../LandingComponents/NavBar";



export default function MainPage() {


  return (
    <>
      <NavBar />
      <Hero />
      <PrimaryFeatures />
      <Pricing />
      {/* <SecondaryFeatures /> */}
      <CallToAction />
      {/* <Reviews /> */}

      <Faqs />
    </>
  )
}

















