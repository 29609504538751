// src/ExcelEditor.js
import { set } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";


const ExcelEditor = ({ data,setPriceList,increase }) => {
  const [priceMap, setPriceMap] = useState(data.priceMap);
   const [actualPriceMap, setActualPriceMap] = useState(data.priceMap);

  useEffect(() => {
    if(increase){
      let percentage = increase/100
      let newPriceMap = {}
      for (const [key, value] of Object.entries(priceMap)) {
        newPriceMap[key] = parseFloat((value + (value * percentage)).toFixed(2))
      }
      setPriceMap(newPriceMap)
      setPriceList({ ...data, priceMap: newPriceMap });
    }else {

      setPriceMap(actualPriceMap)

    }
  }, [increase]);

  const columns = useMemo(() => {
    return [
      { Header: "H\\W", accessor: "size" },
      ...data.columns.map((col) => ({
        Header: col.toString(),
        accessor: col.toString(),
      })),
    ];
  }, [data.columns]);

  const rows = useMemo(() => {
    return data.rows.map((row) => {
      let rowObj = { size: row.toString() };
      data.columns.forEach((col) => {
        const key = `${col}-${row}`;
        rowObj[col.toString()] = priceMap[key] || "";
      });
      return rowObj;
    });
  }, [data.rows, data.columns, priceMap]);

  const updatePrice = (col, row, value) => {
    const key = `${col}-${row}`;
    setPriceMap({ ...priceMap, [key]: parseInt(value) || "" });
    setPriceList({ ...data, priceMap: { ...priceMap, [key]: parseInt(value) || "" } });

  };

  const renderCell = (cell) => {
    const { column, row } = cell;
    if (column.id === "size") {
      return <span className="text-gray-700">{cell.value}</span>;
    }
    return (
      <input
        type="text"
        value={cell.value}
        className="w-16 border border-gray-300 rounded text-right"
        onChange={(e) => updatePrice(column.id, row.values.size, e.target.value)}
      />
    );
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows: tableRows, prepareRow } =
    useTable({ columns, data: rows });

  return (
    <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 table-fixed">
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="text-xs font-medium text-gray-500 uppercase tracking-wider">
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="px-4 py-3 text-left">
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
        {tableRows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="hover:bg-gray-100" >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className="px-4 py-4 w-8 whitespace-nowrap text-sm text-gray-500">
                  {renderCell(cell)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExcelEditor;