import React, { useState, useEffect,useCallback } from "react";

function getInitialSelectedVariants(details) {

  
    if(!details) return {};


    return Object.entries(details)?.reduce(
      (acc, [detailName, details]) => {
        acc[detailName] = {
          value: details[0].variant_detail_value,
          price: details[0].variant_detail_price,
          store_price: details[0].store_variant_detail_price,
        };
        return acc;
      },
      {}
    );
  }

const CreateVariantSelect = ({
  variantName,
  variantValue,
  variantDetails,
  selectedVariants,
  setSelectedVariants,
  handleVariantDetailChange,
  setSelectedDetails,
  selectedDetails,
  selectedDetail,
  item

}) => {

  const [selectedVariantDetails,setSelectedVariantDetails] = useState({})

  // Group by variant_detail_name
  // Group by variant_detail_name
  const groupByDetailName = variantDetails.reduce((acc, detail) => {
    if (!acc[detail.variant_detail_name]) {
      acc[detail.variant_detail_name] = [];
    }
    acc[detail.variant_detail_name].push(detail);
    return acc;
  }, {});

  const handleSelectChange = (detailName, e) => {
    const [vName,vValue, vDetailName, vDetailValue] = e.target.value.split(":")
    // const [v,value, price, store_price] = e.target.value.split(":")
    let price = null;
    let storePrice = null;

    const variation = item.variations[vName].find(variation => variation.variant_value === vValue);
    if (variation) {
      const variantDetail = variation.variant_details.find(detail => detail.variant_detail_name === vDetailName && detail.variant_detail_value === vDetailValue);
      if (variantDetail) {
        price = variantDetail.variant_detail_price;
        storePrice = variantDetail.store_variant_detail_price;
      }
    }
    



    const newVariantDetails = {
      ...selectedVariants[variantName],
      [detailName]: {
        value:vDetailValue,
        price: parseInt(price),
        store_price:parseFloat(storePrice),
      
        

      },
    };

    setSelectedVariants({
      ...selectedVariants,
      [variantName]: newVariantDetails,
    });

    setSelectedDetails({
      ...selectedDetails,
      [variantValue]: {
        details: {
          ...selectedDetails[variantValue]?.details,
          [detailName]: {
            value:vDetailValue,
            price: parseInt(price),
            store_price: parseFloat(storePrice),

          },
        }
      }
    })

    handleVariantDetailChange(newVariantDetails, variantName);
  };

  console.log(item)
return (
    <div>
      {item && Object.entries(groupByDetailName).map(([detailName, details]) => (
        <div key={
          `${variantName}:${variantName}:${detailName}`
        } className="flex flex-col">
          <label className="text-[10px] mt-1 font-bold text-gray-400">
            {detailName.toUpperCase()}
          </label>
          <select
            id={`${variantName}:${detailName}`}
            value={`${item?.variants[variantName]?.name}:${item?.variants[variantName]?.value}:${detailName}:${item?.variants[variantName]?.details[detailName]?.value}`}
           
            
            className="bg-white p-2 w-full mt-1 rounded border"
            style={{ fontSize: "10px" }}
            onChange={(e) => handleSelectChange(detailName, e)}
          >
            {details.map((detail, i) => (
              <option
                key={`${i}:${detail.variant_detail_value}:${detail.variant_detail_price}`}
                value={`${variantName}:${variantValue}:${detail.variant_detail_name}:${detail.variant_detail_value}`}
              >
                {detail.variant_detail_value} 
                {detail.variant_detail_price > 0 ? ` (+$${detail.variant_detail_price})` : ""}
                <a className="text-xs text-indigo-600">{(detail.store_variant_detail_price > 0 && detail.variant_detail_price !==  detail.store_variant_detail_price) ? ` (S.price:$${detail.store_variant_detail_price})` : ""}</a>
    
              </option>
            ))}
          </select>
        </div>
      ))}
    </div>
  );
};

export default function SelectVariantInput({
  variants,
  handleSelectVariant,
  item,
  orderData,
  setOrder,
  index,
  toggleView
}) {
  const [localSelected, setLocalSelected] = useState(null);

  const [selected, setSelected] = useState({});   
  const [selectedDetails, setSelectedDetails] = useState({});   
  



  const handleVariantDetailChange = useCallback((variant_details, variant_name) => {

    let tempSelected  = selected 
    tempSelected[variant_name].details = {
        ...tempSelected[variant_name].details,
        ...variant_details
    }
    setSelected(tempSelected)
    handleSelectVariant(
        tempSelected
    )

}, [handleSelectVariant, selected])

// useEffect(() => {
//   if (orderData[index]?.variants[variantName]) {
//       setLocalSelected(orderData[index].variants[variantName].id);
//       setSelected(orderData[index].variants);
//       setSelectedVariants(orderData[index].variants[variantName].details);
//   }
// }, [orderData, index, variantName]);


useEffect(() => {
  // if(!toggleView){
    const iVariantDetails = {}
    variants?.forEach(variant => {
      iVariantDetails[variant.variant_value] = {
        details: getInitialSelectedVariants(variant.variant_details?.reduce((acc, detail) => {
          if (!acc[detail.variant_detail_name]) {
            acc[detail.variant_detail_name] = [];
          }
          acc[detail.variant_detail_name].push(detail);
          return acc;
        }, {}))
      }
    })
    setSelectedDetails(iVariantDetails)
  // }
}, [variants]);





console.log(selectedDetails)

  return (
    <div className="w-full">


<div 
   className="columns-2" 
    >
        {variants?.map((variant,index) => (
          <div
            key={index}

            className={variant.id === item?.variants[variant.variant_name]?.id  ?  "break-inside-avoid pt-2 pb-4 pl-2 pr-2 cursor-pointer border w-full h-full  mb-4 border-green-600" : "break-inside-avoid pt-2 pb-4 pl-2 pr-2 cursor-pointer border w-full h-full  mb-4" }
            onClick={(e) => {


              // if(e.target.tagName === "SELECT")
              //   return;


              if (variant.id === localSelected) {
                return; // Do nothing if it's a click on the select element and already localSelected
              }

   

              let selected = {
                [variant.variant_name]: {
                  id: variant.id,
                  name: variant.variant_name,
                  key: variant.key,
                  isRule: variant.isRule,
                  value: variant.variant_value,
                  price: variant.variant_price,
                  store_price: variant.store_variant_price,
                  details: 
                  selectedDetails[variant.variant_value]?.details || {}
                },
              }
              handleSelectVariant(selected);
              setSelected(selected)

              setLocalSelected(variant.id);
            }}
          >

            <div className="flex items-center">
    
   

            {variant.variant_value  && variant.variant_value.length >0 && <h3 className="text-sm mt-1">
              <div className="flex flex-col">
              <a className={variant.id === item?.variants[variant.variant_name]?.id ? "font-bold text-green-800" :"font-bold text-gray-600"}>{variant.variant_value} {variant.variant_price > 0 && `(+$${variant.variant_price})`}{" "}</a>
              <a className="text-xs text-indigo-600">{variant.store_variant_price > 0 && `Store price: $${variant.store_variant_price}`}</a>
              </div>
          

            </h3>}
            <a className="text-green-600 text-xs ml-1 mt-1">
                {variant.id === item?.variants[variant.variant_name]?.id && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
<path fill="#c8e6c9" d="M36,42H12c-3.314,0-6-2.686-6-6V12c0-3.314,2.686-6,6-6h24c3.314,0,6,2.686,6,6v24C42,39.314,39.314,42,36,42z"></path><path fill="#4caf50" d="M34.585 14.586L21.014 28.172 15.413 22.584 12.587 25.416 21.019 33.828 37.415 17.414z"></path>
</svg>}
              </a>
            </div>

            {variant.variant_details?.length > 0 ? (
              <div className="flex flex-col">
                {
                  <CreateVariantSelect
                    setSelectedDetails={setSelectedDetails}
                    selectedDetails={selectedDetails}
                    selectedDetail={selectedDetails[variant.variant_value]}
                    variantName={variant.variant_name}
                    variantValue={variant.variant_value}
                    variantDetails={variant.variant_details}
                    selectedVariants={selected[variant.variant_name]?.details || {}}
                    setSelectedVariants={(newVariants) => {
                      setSelected((prevSelected) => ({
                        ...prevSelected,
                        [variant.variant_name]: {
                          ...prevSelected[variant.variant_name],
                          details: newVariants,
                        },
                      }));
                    }}
                    selected={
                        selected
                    }
                    setOrder={setOrder}
                    orderData={orderData}
                    index={index}
                    handleVariantDetailChange={handleVariantDetailChange}
                    item={item}
                
                  />
                }
              </div>
            ) : (
              <div />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
